.container-border-light-gamejam {
  padding-top: 20px;
}

.background-img-gamejam {
  height: 400px;

  background-repeat: no-repeat;

  border: 1px solid #ca4932;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #8e2261;
  position: relative;
}

.content-gamejam h2 {
  font-size: 19px;
}

.box-gamejam {
  position: absolute;
  top: 50%;
  display: f;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  // background: #111845a6;
  box-sizing: border-box;
  overflow: hidden;

  color: white;
}

.box-gamejam:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.4s;
  pointer-events: none;
}

.box-gamejam:hover:before {
  left: 100%;
  transform: skewX(-15deg);
}

.box-gamejam .content-gamejam {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;

  padding: 20px;
  text-align: center;
  // box-shadow: 0 5px 10px rgba(9, 0, 0, 0.5);

  img {
    border-radius: 1rem;
    width: 80%;
  }
}

.box-gamejam span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.box-gamejam span:nth-child(1) {
  transform: rotate(0deg);
}

.box-gamejam span:nth-child(2) {
  transform: rotate(90deg);
}

.box-gamejam span:nth-child(3) {
  transform: rotate(180deg);
}

.box-gamejam span:nth-child(4) {
  transform: rotate(270deg);
}

.box-gamejam span:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #a04c46;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

@media (max-width: 1360px) {
  .container-border-light-gamejam {
    .background-img-gamejam {
      .box-gamejam {
        width: 230px;
        height: 230px;
      }
    }
  }
}

@media (max-width: 1180px) {
  .container-border-light-gamejam {
    .background-img-gamejam {
      .box-gamejam {
        width: 200px;
        height: 200px;
      }
    }
  }
}

@media (max-width: 750px) {
  .container-border-light-gamejam {
    .background-img-gamejam {
      .box-gamejam {
        width: 270px;
        height: 270px;
      }
    }
  }
}

@media (max-width: 380px) {
  .container-border-light-gamejam {
    .background-img-gamejam {
      .box-gamejam {
        width: 220px;
        height: 220px;
      }
    }
  }
}
