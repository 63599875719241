$text-color-white: #ddf2ff;
$bg-content: #0f1112;
$color-primary: #0095fa;

.content-partners {
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  width: 100%;
  padding-top: 100px;
  min-height: 100vh;

  overflow: auto;

  .card-body {
    display: flex;
    transition: all;
    top: 0%;

    justify-content: space-between;
    align-items: center;
    background-color: rgb(23, 23, 47) !important;
  }
}

.container-partners {
  align-items: center;

  .container-info {
    -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
    box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
    transition: all ease 1s;
    p {
      transition: all ease 1s;
    }
  }
}

.modal-header {
  display: none !important;
  background-color: none !important;
}

.card {
  border: none !important;

  .creator {
  }
}

.card-img-top {
  width: 200px;
  height: 150px;
}

/* responsive*/

@media (max-width: 750px) {
  .content-partners {
    padding-top: 100px !important;
  }
  .container-partners {
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
  }
}

@keyframes rotateImg {
  from {
    transform: rotate(360);
  }
  to {
    transform: rotate(0);
  }
}

.img-logo-card {
  animation: rotateImg 1s linear;
}
