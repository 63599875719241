/*var colors*/

$text-color-white: #ddf2ff;
$bg-card: #2f3f58;
$color-primary: #0095fa;

.container-title {
  display: flex;

  h1 {
    font-family: atmospheric;
    font-size: 30px;
  }
}
.text-title {
  color: #fff !important;
  font-size: 27px !important;
  border: 2px solid $color-primary;
  padding: 1rem;
  clip-path: polygon(15% 0, 100% 0, 98% 95%, 88% 100%, 0 98%, 0 17%);
}

.container-header {
  justify-content: flex-end;
  font-size: 40px;
  font-family: atmospheric !important;

  .select-language {
    background: none !important;
  }
}

.select-language {
  background-image: url();
  width: 150px;
  background: none !important;
  box-shadow: none !important;
  margin: 0 10px;
  padding: 1rem;
  -webkit-clip-path: polygon(
    0 40px,
    40px 0,
    100% 0,
    100% calc(100% - 40px),
    calc(100% - 40px) 100%,
    0 100%
  );
  clip-path: polygon(
    0 40px,
    40px 0,
    100% 0,
    100% calc(100% - 40px),
    calc(100% - 40px) 100%,
    0 100%
  );
  min-width: 280px;
  border-radius: 1rem;
  border: 2px solid $color-primary;

  input,
  select {
    color: #1f2122 !important;
    border-radius: 10px;
    padding-left: 2rem;
    -webkit-clip-path: polygon(
      0 40px,
      40px 0,
      100% 0,
      100% calc(100% - 40px),
      calc(100% - 40px) 100%,
      0 100%
    );
  }

  input::placeholder,
  select {
    color: #1f2122;
    font-weight: bolder;
  }
}

.container-btn-wallet {
  display: flex;
  justify-content: flex-end;

  ul {
    button {
      img {
        opacity: 0;
      }
    }
  }

  ul:hover {
    button {
      img {
        transition: opacity ease-in-out 1.4s;
        opacity: 1;
      }
    }
  }

  ul {
    li {
      padding-left: 3rem;
      img {
        opacity: 0;
      }
    }

    li {
      img:hover {
        transform: scale(1.2);
      }
    }
  }

  ul:hover {
    li {
      img {
        transition: opacity ease-in-out 1.4s;
        opacity: 1;
      }
    }
  }
}

.btn-wallet {
  position: relative;

  font-size: 18px;
  font-weight: bolder;
  color: #161d2f;
  width: 300px;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all ease 0.3s;
}
.btn-wallet:hover {
  transform: scale(1.01);
}

.div-btn-wallet {
  display: flex;
  justify-content: center;
  padding: 5px;
}

ul {
  padding: 0 !important;
  margin: 0;
  list-style-type: none;
  display: block;

  button {
    img:hover {
      transform: scale(1.2);
    }
  }
}

.text-btn-wallet {
  position: fixed !important;
  color: $text-color-white;
  font-weight: bolder;
  font-size: 15px;
  padding-top: 10px !important;
  text-align: aling-center;

  text-transform: uppercase;
}

.share-icons {
  position: relative;
  display: flex;
  box-shadow: inset 0 -6px 10px -8px rgba(0, 0, 0, 0.3),
    inset 0 6px 10px -8px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  &__block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    transition: transform 0.3s, filter 0.2s;
    transition-timing-function: ease-in-out;

    &-left,
    &-right {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: $color-primary;
      transition: 0.6s;
    }

    &-left {
      clip-path: inset(0 50% 0 0);
      background-image: url('');
    }

    &-right {
      clip-path: inset(0 0 0 50%);
    }
  }

  &__item {
    padding: 0.5rem;
  }

  &:hover {
    .share-icons__block {
      filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
      z-index: -1;

      &-left {
        transform: translateX(-50%) scale(1.2);
      }

      &-right {
        /* move apenas para esquerda transform: translateX(50%) scale(1.2);*/
      }
    }
  }
}

.icon-metamask-btn-wallet {
  width: 30px !important;
  background: none !important;
}

.share-icons__item {
  background: none !important;
  border: none !important;
}
/* responsive*/

@media (max-width: 1500px) {
  .text-title {
    color: #fff !important;
    font-size: 25px;
    text-align: center;
  }
}

@media (max-width: 1560px) {
  .container-btn-wallet {
    height: 100px !important;
  }
  .text-title {
    color: #fff !important;
    font-size: 25px;
    text-align: center;
  }
}

//responsividade

@media (max-width: 750px) {
  .select-language {
    width: 100px !important;
    margin-top: 300px;
    display: none;
  }
  .content-header {
    width: 100%;
    .content-header {
      padding-top: 300px;
    }
    .container-btn-wallet {
      justify-content: center;
    }
  }
  .text-title {
    font-size: 20px !important;
    position: fixed;
    left: 50%;
    z-index: 10000022222222;
    top: 0;
  }
}
