.content-staking {
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  width: 100%;
  padding-top: 40px;
  min-height: 100vh;

  .container-staking {
    width: 80%;
    margin: 0 auto;

    .wrapper-text-ranking {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        color: rgb(65, 171, 65);
      }
    }
  }
}
