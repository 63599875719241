$radius: 3rem;
$color-primary: #0095fa;
$color-success: #7cc91f;

.content-ranking {
  .header-ranking {
    margin-top: 0.5rem;
    font-size: 18px;
    font-family: atmospheric;
  }
  .container-ranking {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    height: 484px;
    display: block;
    align-items: center;
    border: 2px solid $color-primary;
    border-radius: 1rem;
    clip-path: polygon(
      71% 0,
      100% 0,
      100% 100%,
      0 100%,
      0 72%,
      1% 59%,
      1% 39%,
      0 20%,
      0 0
    );

    .item-ranking {
      display: flex;
      margin-bottom: 1rem;
      gap: 1rem;

      padding-bottom: 1rem;

      .wrapper-text-ranking {
        display: flex;
        flex-direction: column;
        .volume-coin {
          color: $color-success;
          font-weight: bold;
        }
      }

      img {
        border: 4px solid $color-primary;
        width: 100px;
        border-radius: calc($radius - 2rem);
      }
    }
  }
}

@media (max-width: 750px) {
  .container-ranking {
    width: 70%;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .container-ranking {
    width: 100%;
    margin: 0 auto;
  }
}
