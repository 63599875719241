.content-post {
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  display: 100%;
  padding-top: 50px;
  min-height: 100vh;

  .container-post {
    margin: 0 auto;

    .wrapper-post {
      .post-title {
        font-size: 2.8rem;
        margin-top: 3rem;
        margin-bottom: 2rem;
        font-weight: bolder;
      }
      .subtitle-post {
        font-size: 1.5rem;
        font-weight: bold;
      }

      .conclusion-post,
      .intro-post,
      .body-post {
        font-size: 1.4rem;
      }

      .banner-post {
        width: 100%;
        border-radius: 1rem;
        margin-bottom: 2rem;
      }

      .wrapper-date-avatar {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        margin: 1rem 0;

        p {
          margin-top: 20px;
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .content-post {
    padding-top: 100px;
  }

  .post-date {
    font-size: 12px;
  }

  .banner-post {
    width: 100%;
  }

  .post-title {
    margin: 0 !important;
    font-size: 20px !important;
  }

  .mini-menu {
    justify-content: space-between;
    padding: 0 !important;
  }
}
