$magenta: #de0082;
.body-btn-span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  display: flex;
  height: 100%;
  margin: auto 40px;
}

.btn-span {
  display: flex;

  text-decoration: none;
  outline: none;
  font-size: 1rem;

  cursor: pointer;
  position: relative;

  animation: pulse 2s ease-out infinite;
}

span {
  color: transparent;
  text-shadow: 0 0 0 white;
}

.btn::after {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(0, 210, 255), 0 0 0 0px rgba(0, 210, 255, 0.85);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 210, 255, 0), 0 0 0 30px rgba(0, 210, 255, 0);
  }
}

.span-large {
  padding: 1rem;
  width: 80%;
  font-size: 2rem;
  border-radius: 0 !important;
}

.span-medium {
  padding: 0.8rem;
  width: 60%;
  font-size: 1.2rem;
}

.span-small {
  width: 40%;

  font-size: 0.9rem;
  border-radius: 0 !important;

  img {
    width: 120%;
  }
}

//magenta

.body-btn-span-magenta {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  display: flex;
  height: 100%;
  margin: auto 40px;
}

.btn-span-magenta {
  display: flex;

  text-decoration: none;
  outline: none;
  font-size: 1rem;

  cursor: pointer;
  position: relative;

  animation: pulse-magenta 2s ease-out infinite;
}

span {
  color: transparent;
  text-shadow: 0 0 0 white;
}

.btn::after {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
}

@keyframes pulse-magenta {
  0% {
    box-shadow: 0 0 0 0px rgb(255, 0, 98), 0 0 0 0px rgba(255, 0, 98, 0.85);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 0, 98, 0), 0 0 0 30px rgba(255, 0, 98, 0);
  }
}

.span-large-magenta {
  padding: 1rem;
  width: 80%;
  font-size: 2rem;
  border-radius: 0 !important;
}

.span-medium-magenta {
  padding: 0.8rem;
  width: 60%;
  font-size: 1.2rem;
}

.span-smal-magental {
  width: 40%;

  font-size: 0.9rem;
  border-radius: 0 !important;

  img {
    width: 120%;
  }
}
