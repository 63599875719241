/*var colors*/
$primary: #14b1ff;
$text-color-white: #ddf2ff;
$bg-card: #2f3f58;
$color-primary: #0095fa;

#a-table {
  position: relative;
  font-size: 15px;
  font-weight: bold;

  height: 100%;

  color: $text-color-white;
  text-transform: uppercase;

  text-decoration: none;

  overflow: hidden;
  transition: 0.3s;
}
#a-table:hover {
  /* background: #39ff14;
  box-shadow: 0 0 10px #39ff14, 0 0 40px #39ff14, 0 0 80px #39ff14;
  */
  transition-delay: 0.85s;
}
#a-table:focus {
  background-color: $primary;
}
#a-table span {
  position: absolute;
  display: block;
}
/* parte de cima da animação */
#a-table span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, $primary);
}
#a-table:hover span:nth-child(1) {
  left: 100%;
  transition: 0.6s;
}
/* parte de baixo da animação */
#a-table span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, $primary);
}
#a-table:hover span:nth-child(3) {
  right: 100%;
  transition: 0.8s;
  transition-delay: 0.5s;
}
/* parte da direita da animação */
#a-table span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, $primary);
}
#a-table:hover span:nth-child(2) {
  top: 100%;
  transition: 0.8s;
  transition-delay: 0.2s;
}
/* parte da esquerda da animação */
#a-table span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, $primary);
}
#a-table:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.8s;
  transition-delay: 0.7s;
}

/*class b, a mesma coisa que A porém, nao tem evento focus, e background após click*/
