$text-color-white: #ddf2ff;
$bg-card: #0c2834;
$color-primary: #0095fa;

.content-sidebar-right {
  position: fixed;

  height: 40vh;
  right: 0% !important;
  top: 50% !important;
  transform: translateY(-50%);
}
.container-sidebar-right #a {
  height: 80px !important;
  width: 80px !important;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  justify-content: center;

  span {
    width: 100%;
  }
}

.container-sidebar-right {
  .div-btn-sidebar-right {
    .globo {
      width: 40px !important;
      margin: 0 auto !important;
      margin-left: 5px;
    }
    .dropstart {
      button {
        background: none;
        border: none;
        padding: 0 !important;
      }
      border: none;
      outline: none !important;
      width: 60px;
      img {
        margin-left: 1rem;
        width: 15px;
        position: static;
      }
    }

    .dropdown-menu {
      background: $color-primary;

      font-weight: bolder;
      box-shadow: inset 0 0 14px -3px rgb(43 214 255 / 40%) !important;
      font-family: atmospheric;
    }
  }

  flex-direction: column;

  button {
    margin-bottom: 20px;
    transition: all ease 0.3s;
  }
}

.div-btn-sidebar-right {
  img {
    width: 50%;
  }
}

@media (max-width: 750px) {
  .content-sidebar-right {
    margin-top: 1rem;
    height: 80px !important;
    right: 0% !important;
    width: 100% !important;
    display: flex;
    position: static;

    .container-sidebar-right {
      height: 50px !important;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 0 !important;
      .div-btn-sidebar-right {
        width: 40px;
        button {
          visibility: show !important;

          width: 100%;
        }
      }
    }
  }
}
