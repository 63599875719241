$text-color-white: #ddf2ff;
$bg-content: #0f1112;
$color-primary: #0095fa;
.content-team {
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  .container-team {
    display: flex;
    justify-content: center;
    align-items: center;

    .core-team,
    .comando-team {
      h1 {
        font-family: atmospheric;
        margin: 5rem 0;
      }
    }
  }
}

.nfts {
  h1 {
    position: absolute;
    top: 40%;
    transform: translateY(-30%);
    font-size: 50px !important;
    font-family: atmospheric;
    text-align: center;
  }
}

@media (max-width: 750px) {
  .content-team {
    padding-top: 100px;
  }
}
