$text-color-white: #ddf2ff;
$bg-content: #0f1112;
$color-primary: #0095fa;
$magenta: #de0082;
$notchSize: 40px;

.content-live {
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  width: 100%;
  min-height: 100vh;

  .btn-span-live {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: red;
  }

  h1 {
    position: absolute;
    top: 40%;
    transform: translateY(-30%);
    font-size: 50px !important;
    font-family: atmospheric;
    text-align: center;
  }

  padding-top: 180px;

  min-height: 100vh;
  width: 100%;
  .container-live {
    width: 70%;
    margin: 0 auto;
    .container-video {
      .video {
        border: 2px solid $color-primary;
        height: 500px;
        border-radius: 1.3rem;
        padding: 1.3rem;
        clip-path: polygon(8% 0, 100% 0, 100% 95%, 69% 100%, 0 100%, 0 9%);
      }
    }

    .wrapper-description-live {
      border: 2px solid $color-primary;
      margin-top: 30px;
      padding: 0.5rem;
      border-radius: 0.5rem;
      clip-path: polygon(0 0, 57% 0, 100% 7%, 100% 100%, 26% 100%, 0 94%);
    }
  }

  .btn-pulsar {
    background: none;
    background: $color-primary;
    color: white;
    border: none;
    border-radius: 0.3rem;
    margin-bottom: 10px;
  }
}

#messages {
  background: #272730 !important;

  .rcw-message-text {
    background: $color-primary !important;
  }
}

.rcw-widget-container {
  position: static !important;
  box-shadow: none !important;

  .rcw-launcher {
    position: absolute;
    bottom: 4% !important;
    right: 3% !important;
    box-shadow: none !important;
  }
  .rcw-conversation-container {
    box-shadow: none !important;
  }

  .rcw-input {
    color: #171720 !important;
  }
}
