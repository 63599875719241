$text-color-white: #ddf2ff;
$magenta: #de0082;
$bg-content: #1f2122;
$bg-container: #27292a;
$color-primary: #0095fa;

.content-gameinfo {
  min-height: 100vh;
  padding-top: 180px;
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );

  width: 100%;
  min-height: 100vh;
  .container-gameinfo #a {
    span {
      width: 100%;
    }
  }

  .container-gameinfo {
    width: 70%;

    margin: 0 auto;

    .titile-gameinfo {
      font-size: 25px;
      font-family: atmospheric;
      text-align: center;
      margin-top: 20px;
    }

    .wrapper-categories {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-family: atmospheric;

      p {
        margin: 0 auto;
        margin-top: 1rem;
        font-size: 13px;
      }
    }

    .container-header-gameinfo {
      border: 2px solid $color-primary;
      clip-path: polygon(8% 0, 100% 0, 100% 91%, 90% 100%, 0 100%, 0 8%);
      border-radius: 1rem;

      img {
        width: 30px;
        height: 30px;
      }

      .text-plarforms {
        font-size: 10px;
      }

      .text-categories,
      .text-platforms {
        font-family: atmospheric;
      }
    }

    .container-sidebar-gamefinfo {
      border: 2px solid $color-primary;
      border-radius: 1rem;
      padding: 2rem;
      clip-path: polygon(0 0, 66% 0, 100% 13%, 100% 100%, 12% 100%, 0 87%);

      .img-platform {
        width: 40px;
      }
    }

    .title-rating {
      font-family: atmospheric;
      display: flex;
      justify-content: flex-end;
      padding-right: 50px;
      font-size: 20px;
      margin-top: 1rem;
    }
    .wrapper-items-gameinfo {
      padding: 0 !important;
      border-radius: 2rem;
      clip-path: polygon(
        22% 0,
        100% 0,
        100% 35%,
        100% 75%,
        75% 100%,
        52% 100%,
        29% 100%,
        0 100%,
        0% 35%,
        0 22%
      );

      border: 2px solid $color-primary;
      border-radius: 1rem;
    }

    .wrapper-socials-gameinfo {
      display: flex;
      justify-content: center;
      gap: 1rem;
      button {
        height: 80px !important;
        width: 80px !important;
        font-size: 12px !important;

        img {
          width: 25px;
        }
      }
    }
  }
}

.wrapper-sidebar-gameinfo {
  display: flex;
  gap: 1rem;
  img {
    width: 20px;
    height: 20px;
  }
}

.btn-clip-path:hover {
  box-shadow: inset 0 0 14px -3px rgb(43 214 255 / 40%);
}
