$text-color-white: #ddf2ff;
$bg-card: #2f3f58;
$color-primary: #0095fa;
.content-hub-home {
  padding-top: 50px;
  height: 100vh;
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  .rec-arrow-right {
    background: none !important;
    color: $color-primary;
  }

  .rec-arrow-left {
    background: none !important;
    color: $color-primary;
  }

  .container-hub-home {
    display: flex;
    height: 100%;

    .dropdown-menu {
      background: $color-primary;

      font-weight: bolder;
      box-shadow: inset 0 0 14px -3px rgb(43 214 255 / 40%) !important;
      font-family: atmospheric;
    }

    .dropstart {
      width: 100px;
      right: 3%;
      top: 13%;
      position: absolute;

      button {
        border: none;

        background: none;
        img {
          width: 40px;
        }
      }
    }
  }
}

.btn-carousel {
  position: relative;
  margin: auto 0;
  .wrapper-title-promo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    h2 {
      font-size: 25px;
      padding-top: 5px;
    }

    button {
      color: white;

      width: 140px;
      margin: 0.5rem;
      background: $color-primary;
      border: none;
    }
  }

  .container-promo-hub {
    border: 2px solid $color-primary;
    padding: 1rem;
    position: relative;
    width: 100%;
    .wrapper-release {
      .text-release {
        position: absolute;
        font-size: 12px !important;
        letter-spacing: 4px;
        font-family: atmospheric;
        top: 5%;
        left: 3%;
        transform: translateY(-50%);
      }
    }
    video {
      height: 100%;
      width: 100%;

      clip-path: polygon(23% 6%, 29% 0, 100% 0, 100% 100%, 0 100%, 0 6%);
    }
  }
}

.icon-socials-discord {
  bottom: 1%;
  position: absolute !important;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  left: 50%;
  animation: socialsRocketAnimationDiscord 4.3s both;
}
.icon-socials-twitter {
  bottom: 1%;
  position: absolute !important;
  width: 80px;
  height: 80px;
  border-radius: 50%;

  left: 40%;
  animation: socialsRocketAnimationTwitter 5.9s both;
}

.icon-socials-telegram {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  bottom: 1%;
  position: absolute !important;

  left: 60%;
  animation: socialsRocketAnimationTelegram 3s both;
}

.icon-socials-twitter:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}
.icon-socials-telegram:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

.icon-socials-discord:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

.btn.focus,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.pushRocketDiscord {
  position: absolute;
  animation: pushRocketButtonAnimation 2s both !important;
  transform: rotateZ(269.5deg);
}

.pushRocketTelegram {
  position: absolute;
  animation: pushRocketButtonAnimation 2s both !important;
  transform: rotateZ(269.5deg);
}

.pushRocketTwitter {
  position: absolute;
  animation: pushRocketButtonAnimation 2s both !important;
  transform: rotateZ(269.5deg);
}

.img-rocket {
  transition: all ease 0.2s;
}

.typerwrite {
  bottom: 0 !important;
  font-size: 30px;
  position: absolute;
  color: white;
}

.icon-socials-text {
  position: absolute !important;
  display: flex;
  align-content: center;
  gap: 2rem;
  left: 15%;
  animation: socialsRocketAnimationText 4.3s both;
  p {
    font-size: 15px;
    font-family: atmospheric;
  }
  img {
    width: 80px;
  }
}
/*animations socials buttons in /hub */

/*push socials buttons animations*/

@keyframes pushRocketButtonAnimation {
  from {
    position: absolute;

    bottom: 0%;
  }

  to {
    bottom: 100%;
    margin-bottom: 400px;
    bottom: 100%;
  }
}
@keyframes socialsRocketAnimationTwitter {
  0% {
    position: absolute;
    left: 0;
    transform: rotateZ(10deg);
  }
  50% {
    left: 40%;

    transform: rotateZ(180deg);
  }
  100% {
    left: 70%;

    transform: rotateZ(269.5deg);
  }
}

@keyframes socialsRocketAnimationDiscord {
  0% {
    position: absolute;
    left: 0;
    transform: rotateZ(10deg);
  }
  50% {
    left: 50%;

    transform: rotateZ(180deg);
  }
  100% {
    left: 50%;

    transform: rotateZ(269.5deg);
  }
}

@keyframes socialsRocketAnimationText {
  0% {
    position: absolute;
    bottom: -15%;
    //transform: rotateZ(10deg);
  }

  100% {
    bottom: 1%;
    // transform: rotateZ(269.5deg);
  }
}

@keyframes socialsRocketAnimationTelegram {
  0% {
    position: absolute;
    left: 0;
    transform: rotateZ(10deg);
  }
  50% {
    left: 60%;

    transform: rotateZ(180deg);
  }
  100% {
    left: 60%;

    transform: rotateZ(269.5deg);
  }
}
@keyframes socialsRocketAnimationText {
  0% {
    position: absolute;
    bottom: -15%;
    //transform: rotateZ(10deg);
  }

  100% {
    bottom: 3%;
    // transform: rotateZ(269.5deg);
  }
}

@media (max-width: 1760px) {
  @keyframes socialsRocketAnimationDiscord {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 50%;

      transform: rotateZ(180deg);
    }
    100% {
      left: 50%;

      transform: rotateZ(269.5deg);
    }
  }

  @keyframes socialsRocketAnimationTelegram {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 60%;

      transform: rotateZ(180deg);
    }
    100% {
      left: 60%;

      transform: rotateZ(269.5deg);
    }
  }

  @keyframes socialsRocketAnimationTwitter {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 40%;

      transform: rotateZ(180deg);
    }
    100% {
      left: 70%;

      transform: rotateZ(269.5deg);
    }
  }

  .content-hub-home {
    padding-top: 20px;
  }
  .icon-socials-text {
    p {
      font-size: 12px;
    }
  }

  .container-rockets #a {
    width: 80px;
    bottom: 0 !important;
  }

  .btn-carousel {
    position: relative;
    margin: auto 0;
    .wrapper-title-promo {
      display: flex;
      justify-content: space-between;

      h2 {
        font-size: 25px;
      }

      button {
        color: white;

        width: 140px;
        margin: 0.5rem;
        background: $color-primary;
        border: none;
      }
    }

    .wrapper-carousel-promo {
      width: 80%;
    }
  }
}
@media (max-width: 1560px) {
  .container-hub-home {
    height: 75% !important;
  }
}

@media (max-width: 980px) {
  .content-hub-home {
    padding-top: 140px;
  }

  .btn-carousel {
    .wrapper-title-promo {
      h2 {
        font-size: 16px;
      }
    }
    .container-promo-hub {
      .wrapper-release {
        .text-release {
          font-size: 8px !important;
        }
      }
    }
  }

  .wrapper-carousel-promo {
    width: 100%;
  }

  .icon-socials-text {
    left: 50%;
    transform: translateX(-50%);
    p {
      font-size: 8px;
    }
  }

  @keyframes socialsRocketAnimationText {
    0% {
      position: absolute;
      bottom: -15%;
      //transform: rotateZ(10deg);
    }

    100% {
      bottom: 0.3%;
      // transform: rotateZ(269.5deg);
    }
  }

  @keyframes socialsRocketAnimationDiscord {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 10%;

      transform: rotateZ(180deg);
    }
    100% {
      left: 16%;

      transform: rotateZ(269.5deg);
    }
  }

  @keyframes socialsRocketAnimationTelegram {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 60%;

      transform: rotateZ(180deg);
    }
    100% {
      left: 70%;

      transform: rotateZ(269.5deg);
    }
  }

  @keyframes socialsRocketAnimationTwitter {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 20%;

      transform: rotateZ(180deg);
    }
    100% {
      left: 45%;

      transform: rotateZ(269.5deg);
    }
  }
}

@media (max-width: 750px) {
  .icon-socials-telegram,
  .icon-socials-discord,
  .icon-socials-twitter {
    top: 85%;
  }

  .icon-socials-text {
    width: 80%;

    p {
      margin: 0 auto;
    }
  }
  .btn-carousel {
    .wrapper-title-promo {
      h2 {
        font-size: 14px;
      }
    }
  }

  .container-promo-hub {
    padding: 0.2rem !important;
    .wrapper-release {
      .text-release {
        font-size: 6px !important;
      }
    }
    video {
      width: 100%;

      clip-path: polygon(23% 6%, 29% 0, 100% 0, 100% 100%, 0 100%, 0 6%);
    }
  }
}
