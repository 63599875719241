$bg-content: #1f2122;
$bg-container: #27292a;
$color-primary: #0095fa;

//cryptocard

//crypto list

.crypto_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

//pagination

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  width: 40px;
  height: 40px;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: #eee;
  border-color: #eee;
}

.pagination button.active {
  font-weight: 900;
  border-color: $color-primary;
  background: $color-primary;
  color: white;
}

.img-table {
  width: 30px;
}

//table

.input-table {
  margin: 0 10px;
  padding: 1rem;
  -webkit-clip-path: polygon(
    0 40px,
    40px 0,
    100% 0,
    100% calc(100% - 40px),
    calc(100% - 40px) 100%,
    0 100%
  );
  clip-path: polygon(
    0 40px,
    40px 0,
    100% 0,
    100% calc(100% - 40px),
    calc(100% - 40px) 100%,
    0 100%
  );
  min-width: 280px;
  border-radius: 1rem;
  border: 2px solid $color-primary;

  input,
  select {
    color: #1f2122 !important;
    border-radius: 10px;
    padding-left: 2rem;
    -webkit-clip-path: polygon(
      0 40px,
      40px 0,
      100% 0,
      100% calc(100% - 40px),
      calc(100% - 40px) 100%,
      0 100%
    );
  }

  input::placeholder,
  select {
    color: #1f2122;
    font-weight: bolder;
  }
}

//paginate posta
