/*var colors*/
$primary: #14b1ff;

$magenta: #e5097f;
#a {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  height: 100px;
  width: 100px;
  color: rgb(171, 165, 165);
  text-transform: uppercase;

  text-decoration: none;
  text-align: center;

  overflow: hidden;
  transition: 0.3s;

  border-radius: 50%;
}
#a:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.9);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

/*
#a:hover {
   background: #39ff14;
  box-shadow: 0 0 10px #39ff14, 0 0 40px #39ff14, 0 0 80px #39ff14;

  transition-delay: 0.85s;
}
*/
#a:focus {
  background-color: none;
}
#a span {
  position: absolute;
  display: block;
}

#a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, $primary);
}
#a:hover span:nth-child(1) {
  left: 100%;
  transition: 0.6s;
}

#a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, $primary);
}
#a:hover span:nth-child(3) {
  right: 100%;
  transition: 0.8s;
  transition-delay: 0.5s;
}
#a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, $primary);
}
#a:hover span:nth-child(2) {
  top: 100%;
  transition: 0.8s;
  transition-delay: 0.2s;
}

#a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, $primary);
}
#a:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.8s;
  transition-delay: 0.7s;
}

$primary: #14b1ff;

#b {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  height: 60px;

  color: rgb(171, 165, 165);
  text-transform: uppercase;

  text-decoration: none;
  text-align: center;

  overflow: hidden;
  transition: 0.3s;
}
#b:hover {
  transition-delay: 0.85s;
}

#b span {
  position: absolute;
  display: block;
}

#b span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, $primary);
}
#b:hover span:nth-child(1) {
  left: 100%;
  transition: 0.6s;
}

#b span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, $primary);
}
#b:hover span:nth-child(3) {
  right: 100%;
  transition: 0.8s;
  transition-delay: 0.5s;
}

#b span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, $primary);
}
#b:hover span:nth-child(2) {
  top: 100%;
  transition: 0.8s;
  transition-delay: 0.2s;
}

#b span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, $primary);
}
#b:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.8s;
  transition-delay: 0.7s;
}
