$text-color-white: #ddf2ff;
$bg-card: #0c2834;
$color-primary: #0095fa;
$magenta: #e5097f;
/*.content-hidden {
  display: none;
}
*/
.content-sidebar {
  position: fixed !important;

  height: 100vh;
  width: 6rem !important;
  left: 0;
  top: 10%;
  /*background-color: $bg-card; 

  border-right: 1px solid RGBA(0, 0, 49, 0.9);
  -webkit-box-shadow: 8px 2px 28px -7px rgba(0, 0, 0, 0.25);
  box-shadow: 8px 2px 28px -7px rgba(0, 0, 0, 0.25);
  */

  .navbar-nav {
    .nav-item {
      a {
        text-decoration: none !important;
      }
    }
  }

  .container-sidebar {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column !important;
    justify-content: space-between;

    .logo-sidebar {
      height: 60px;
      width: 60px !important;
      border-radius: 50%;
      filter: brightness(120%);
    }

    #a {
      padding: 10px 0;
      border-left: 2px solid transparent;
      width: 80px;
      height: 80px;
    }
    #a:focus {
      padding: 10px 0;
      border-top: 2px solid transparent;
      border-left: 2px solid $color-primary;
      width: 80px !important;
      height: 70px !important;
      border-radius: 0 !important;
      height: 70%;
    }

    a {
      width: 80px !important;
      margin: 0 !important;

      span {
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
      }
      img {
        margin: 0 auto !important;
      }
    }

    a:focus {
      background-color: none;
    }

    .icons-sidebar {
      width: 40% !important;
    }
  }
}

.user-container {
  width: 100%;
}
.user {
  display: flex;

  img {
    width: 40%;
  }
}

.user,
.nfts,
.staking,
.hub,
.partners,
.games,
.lives,
.metaverse,
.team,
.market {
  display: flex;
  align-items: center !important;
  justify-content: space-around !important;

  p {
    font-size: 11px;
  }
}

/*responsive*/

@media (max-width: 750px) {
  .btn-dropup {
    width: 70%;
  }
}

/*responsive */

@media (max-width: 750px) {
  .content-sidebar {
    display: none;
    #a:focus {
      padding: 10px 0;

      width: 80px !important;
      height: 80px !important;
      border-radius: 50% !important;
    }
  }

  .navbar {
    .nav-item {
      height: 100%;
      margin: 0 auto;
      width: 100%;
      display: flex;
      .share-icons {
        margin: 0 auto;
      }
      a {
        margin: 0 auto;
        p {
          font-family: atmospheric;
        }
      }
    }

    .navbar-nav {
      margin-top: 60px;
      overflow-y: scroll !important;
      height: 100%;
      .nav-item {
        a {
        }
      }
    }

    .container-fluid {
      background: #0c2834 !important;

      .home {
        height: 70px;
        font-size: 14px;
      }
      .wrapper-logo-sidebar {
        margin: 0 !important;
        background: black;
        border-radius: 50% !important;
        position: absolute !important;
        left: 50%;
        transform: translateX(-50%);
        top: 0 !important;

        .logo-sidebar {
          width: 100px !important;
          background: black;
          border-radius: 50% !important;
          -webkit-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
        }
      }
    }

    padding: 0 !important;

    #a {
      margin: 0 auto;
    }
  }
}

@media (max-width: 1050px) {
  .content-sidebar {
    display: none;
  }

  .navbar #a {
    span {
      width: 100%;
    }
  }
  .navbar {
    .nav-item {
      height: 100%;
      margin: 0 auto;
      width: 100%;
      display: flex;
      .share-icons {
        margin: 0 auto;
      }
      a {
        margin: 0 auto;
        height: 80px;
        width: 80px;

        p {
          font-family: atmospheric;
        }

        img {
        }
      }
    }

    .navbar-nav {
      margin-top: 60px;
      overflow-y: scroll !important;
      height: 100%;
      .nav-item {
        a {
          height: 70px !important;
          width: 70px !important;
          img {
            width: 30px;
          }
        }
      }
    }

    .container-fluid {
      background: #0c2834 !important;

      .home {
        height: 70px;
        font-size: 14px;
      }
      .wrapper-logo-sidebar {
        margin: 0 !important;
        background: black;
        border-radius: 50% !important;
        position: absolute !important;
        left: 50%;
        transform: translateX(-50%);
        top: 0 !important;

        .logo-sidebar {
          width: 100px !important;
          background: black;
          border-radius: 50% !important;
          -webkit-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
        }
      }
    }

    padding: 0 !important;

    #a {
      margin: 0 auto;
    }
  }
}

#navbarText {
  transition: all ease-in-out 0.2s;
}
