$color-primary: #0095fa;
.content-startups {
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  width: 100%;

  padding-top: 50px;
  min-height: 100vh;

  .container-startups {
    margin: 0 auto;

    .btn-demo {
      display: flex;
      align-items: center;
      justify-items: center;
      flex-direction: column;
      width: 80px;
      height: 30px;
      background: $color-primary;

      border: none;

      p {
        margin: auto 0;
        text-align: center;
        font-size: 14px;
        color: white;
      }
    }
  }
}

@media (max-width: 750px) {
  .apresentation-startups {
    font-size: 14px;
    margin: 0 auto;
    text-align: center !important;
  }

  .content-startups {
    padding-top: 150px;
  }
}
