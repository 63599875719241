.content-market {
  min-height: 100vh;
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  width: 100%;

  .container-market {
    width: 70%;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    h1 {
      position: absolute;
      top: 40%;
      transform: translateY(-30%);
      font-size: 50px !important;
      font-family: atmospheric;
      text-align: center;
    }
  }
}
