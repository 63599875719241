$radius: 3rem;
$color-primary: #0095fa;
$notchSize: 40px;
.rec-carousel-wrapper {
  margin-bottom: 200px;
  margin: 0 !important;
}

.container-carousel {
  margin-top: 3% auto;
  width: 100%;
}

.title-inset-carousel {
  font-size: 20px;
  left: 3%;
  top: 3%;
  position: absolute;
  font-weight: bolder;
  text-transform: uppercase;
  font-family: atmospheric;

  transform: translateX(-50%);
  transform: translateY(-50%);
}

.hidden-pagination {
  display: none !important;
}

.rec.rec-item-wrapper {
  .info-card {
    border: 2px solid $color-primary;
    margin: 10px 10px 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: calc($radius - 2rem);

    .wrapper-name-game {
      margin: 20px 0 0 0 !important;
      padding: 0 !important;
      p {
        text-align: center;
        font-weight: bolder;
        font-size: 14px;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .wrapper-icon-price {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 6px auto 0 !important;
      padding: 0 !important;
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 18px;
      }
    }
  }
}

.img-carousel {
  padding: 1rem;
  clip-path: polygon(
    0% $notchSize,
    $notchSize 0%,
    100% 0%,
    100% calc(100% - #{$notchSize}),
    calc(100% - #{$notchSize}) 100%,
    0px 100%
  );

  min-width: 280px;
  border-radius: calc($radius - 2rem);

  border: 2px solid $color-primary;
}

.rec.rec-dot {
  border: 1px solid #14b1ff !important;
  background-color: none;
}

.carousel-games {
  position: relative;
  padding-top: 5rem;
  .rec-arrow-right {
    position: absolute;
    top: 2% !important;
    right: 2%;
    background: none !important;
    color: $color-primary;
    transform: translateX(-50%);
    transform: translateY(-50%);
  }

  .rec-arrow-left {
    position: absolute;
    top: 2% !important;
    right: 7%;
    background: none !important;
    color: $color-primary;
    transform: translateX(-50%);
    transform: translateY(-50%);
  }

  .rec-arrow-left:hover {
    box-shadow: inset 0 0 14px -3px rgb(43 214 255 / 40%);
  }

  .rec-arrow-right:hover {
    box-shadow: inset 0 0 14px -3px rgb(43 214 255 / 40%) !important;
  }

  .rec-slider-container {
    padding: 0 !important;
    width: 100%;
  }
}

@media (max-width: 750px) {
  .container-carousel {
    h1 {
      margin-top: 30px;
    }
    margin: 0 auto;

    .img-carousel {
      margin-top: 50px;
    }
  }
}

@media (max-width: 480px) {
  .rec-carousel-wrapper {
    padding-top: 0.2rem !important;
    margin-bottom: 200px;
    margin: 0 !important;
  }

  .carousel-games {
    position: relative;
    .rec-arrow-right {
      position: static;
    }

    .rec-arrow-left {
      position: static;
    }

    .rec-arrow-left:hover {
      box-shadow: inset 0 0 14px -3px rgb(43 214 255 / 40%);
    }

    .rec-arrow-right:hover {
      box-shadow: inset 0 0 14px -3px rgb(43 214 255 / 40%) !important;
    }

    .rec-slider-container {
      padding: 0 !important;
      width: 100%;
    }
  }
}

@media (max-width: 1380px) {
  .img-carousel {
    padding: 1rem;
    clip-path: polygon(
      0% $notchSize,
      $notchSize 0%,
      100% 0%,
      100% calc(100% - #{$notchSize}),
      calc(100% - #{$notchSize}) 100%,
      0px 100%
    );

    min-width: 180px;
    border-radius: calc($radius - 2rem);

    border: 2px solid $color-primary;
  }
}
