@charset "UTF-8";
/*var colors*/
#a {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  height: 100px;
  width: 100px;
  color: rgb(171, 165, 165);
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  transition: 0.3s;
  border-radius: 50%;
}

#a:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.9);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

/*
#a:hover {
   background: #39ff14;
  box-shadow: 0 0 10px #39ff14, 0 0 40px #39ff14, 0 0 80px #39ff14;

  transition-delay: 0.85s;
}
*/
#a:focus {
  background-color: none;
}

#a span {
  position: absolute;
  display: block;
}

#a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #14b1ff);
}

#a:hover span:nth-child(1) {
  left: 100%;
  transition: 0.6s;
}

#a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #14b1ff);
}

#a:hover span:nth-child(3) {
  right: 100%;
  transition: 0.8s;
  transition-delay: 0.5s;
}

#a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #14b1ff);
}

#a:hover span:nth-child(2) {
  top: 100%;
  transition: 0.8s;
  transition-delay: 0.2s;
}

#a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #14b1ff);
}

#a:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.8s;
  transition-delay: 0.7s;
}

#b {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  height: 60px;
  color: rgb(171, 165, 165);
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  transition: 0.3s;
}

#b:hover {
  transition-delay: 0.85s;
}

#b span {
  position: absolute;
  display: block;
}

#b span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #14b1ff);
}

#b:hover span:nth-child(1) {
  left: 100%;
  transition: 0.6s;
}

#b span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #14b1ff);
}

#b:hover span:nth-child(3) {
  right: 100%;
  transition: 0.8s;
  transition-delay: 0.5s;
}

#b span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #14b1ff);
}

#b:hover span:nth-child(2) {
  top: 100%;
  transition: 0.8s;
  transition-delay: 0.2s;
}

#b span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #14b1ff);
}

#b:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.8s;
  transition-delay: 0.7s;
}

.text-name-card {
  text-align: center;
  font-weight: 400;
  margin: 8px 0;
}

.text-name-card-team {
  color: rgb(217, 204, 204);
  text-align: center;
  letter-spacing: 3px;
  font-size: 24px;
  margin: 10px 0;
}

.cart-container {
  width: 600px !important;
  display: block;
}

.nft {
  cursor: pointer;
  user-select: none;
  width: 300px;
  margin: 1rem auto;
  border: 1px solid rgba(12, 182, 0, 0.1333333333);
  background-color: #0095fa;
  background: linear-gradient(0deg, rgb(6, 7, 38) 0%, rgba(55, 2, 102, 0.3) 100%);
  box-shadow: 0 7px 20px 5px rgba(0, 0, 0, 0.5333333333);
  border-radius: 0.7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: 0.5s all;
}
.nft hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(136, 136, 136, 0.3333333333);
  margin-top: 0;
}
.nft ins {
  text-decoration: none;
}
.nft .main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}
.nft .main .container-img-card {
  max-width: 100%;
  height: 250px;
}
.nft .main .container-img-card .img-team {
  border-radius: 50%;
  top: 10.3%;
  left: 16%;
  z-index: 1;
  width: 70%;
  border: 2px solid rgb(70, 10, 134);
  filter: brightness(80%);
  position: absolute !important;
  opacity: 1;
  transition: opacity ease-in-out 0.5s;
}
.nft .main .container-img-card .img-nft {
  border-radius: 50%;
  border: 2px solid rgb(70, 10, 134);
  top: 10.3%;
  left: 16%;
  position: absolute;
  width: 70%;
  transition: opacity ease 0.5s;
}
.nft .main .img-team:hover {
  opacity: 0;
  animation: float 6s ease-in-out infinite;
}
.nft .main .description {
  margin: 0.5rem 0;
  color: #a89ec9;
}
.nft .main .tokenInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nft .main .tokenInfo .price {
  display: flex;
  font-size: 13px;
  align-items: center;
  color: #ee83e5;
  font-weight: 400;
}
.nft .main .tokenInfo .price ins {
  margin-left: -0.3rem;
  margin-right: 0.5rem;
}
.nft .main .tokenInfo .duration {
  display: flex;
  align-items: center;
  color: #a89ec9;
  margin-right: 0.2rem;
  font-size: 12px;
}
.nft .main .tokenInfo .duration ins {
  margin: 0.5rem;
  margin-bottom: 0.4rem;
}
.nft .main .creator {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: -0.3rem;
}
.nft .main .creator ins {
  color: #a89ec9;
  text-decoration: none;
}
.nft .main .creator .wrapper {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1333333333);
  padding: 0.3rem;
  margin: 0;
  margin-right: 0.5rem;
  border-radius: 100%;
  box-shadow: 0 0 0 4px rgba(26, 134, 167, 0.6666666667);
}
.nft .main .creator .wrapper img {
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1333333333);
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  margin: 0;
  animation: spiner-img-card 0.5s both;
}
@keyframes spiner-img-card {
  from {
    transform: rotate(-760deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.nft ::before {
  position: fixed;
  content: "";
  box-shadow: 0 0 100px 40px rgba(255, 255, 255, 0.031372549);
  top: -10%;
  left: -100%;
  transform: rotate(-45deg);
  height: 60rem;
  transition: 0.8s all;
}
.nft:hover {
  position: relative;
  z-index: 1000;
  border: 1px solid #0095fa;
  box-shadow: 0 3px 5px 1px #236da9;
  transform: scale(1.015);
}
.nft:hover ::before {
  filter: brightness(0.5);
  top: -100%;
  left: 200%;
}

.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg h1 {
  font-size: 20rem;
  filter: opacity(0.5);
}

.img-card-partners {
  width: 100%;
  margin: 0 auto;
}

/*responsive*/
@media (max-width: 750px) {
  .container-cards {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}
/*var colors*/
.content-games {
  padding-top: 50px;
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  min-height: 100vh;
  width: 100%;
}
.content-games .icon-crown-rating {
  width: 40px;
  margin-bottom: 10px;
}
.content-games .icon-best-rating {
  width: 30px;
  height: 30px;
}
.content-games .title-top-6 {
  font-family: atmospheric;
  margin: 300px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.content-games .title-top-6 img {
  margin-bottom: 1rem;
  width: 60px;
  height: 60px;
}

.link-view-allgames {
  margin-top: 7rem;
}

.container-games {
  margin: 0 auto;
  overflow: hidden !important;
}

.wrapper-title-section {
  width: 100%;
}

.wrapper-posts {
  height: 400%;
}

.text-games {
  margin-top: 100px;
  font-size: 90px !important;
  font-weight: bolder;
  color: white !important;
  text-align: center !important;
}

.container-filter {
  height: 400px;
  overflow: auto;
  display: flex;
  width: 100%;
}

.container-filter-games {
  width: 100%;
}

.btn-clear {
  color: white;
  width: 80%;
  margin-left: 15px;
  width: 70px;
  background: none;
  border: none;
}

.card-best-rating {
  border: 2px solid #0095fa;
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
  clip-path: polygon(0% 40px, 40px 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0px 100%);
  display: flex;
}
.card-best-rating .info-card {
  margin: 0 auto;
}
.card-best-rating .info-card .wrapper-name-game div .icon-best-rating {
  margin: 10px 0 !important;
}
.card-best-rating .info-card .wrapper-name-game div p {
  margin: 0 !important;
}

.icon-trash {
  width: 25px;
  margin-right: 10px;
  background-color: none;
}

.tr {
  margin: 5px !important;
}

/*responsive*/
@media (max-width: 1500px) {
  .container-games {
    overflow: auto;
    margin: 0 auto !important;
  }
}
@media (max-width: 950px) {
  .btn-clear img {
    width: 20px;
  }
  .btn-clear p {
    font-size: 10px;
  }
}
@media (max-width: 750px) {
  .title-top-6 {
    display: flex;
  }
  .title-top-6 p {
    font-size: 18px !important;
  }
  .title-top-6 img {
    width: 70px !important;
    margin: 0 !important;
  }
  .container-best-games {
    width: 70%;
    margin: 0 auto;
  }
  .content-games {
    padding-top: 120px;
  }
  .content-games .title-inset-carousel {
    left: 40%;
    left: 50%;
  }
  .container-games {
    width: 100% !important;
  }
}
/*var colors*/
.container-title {
  display: flex;
}
.container-title h1 {
  font-family: atmospheric;
  font-size: 30px;
}

.text-title {
  color: #fff !important;
  font-size: 27px !important;
  border: 2px solid #0095fa;
  padding: 1rem;
  clip-path: polygon(15% 0, 100% 0, 98% 95%, 88% 100%, 0 98%, 0 17%);
}

.container-header {
  justify-content: flex-end;
  font-size: 40px;
  font-family: atmospheric !important;
}
.container-header .select-language {
  background: none !important;
}

.select-language {
  background-image: url();
  width: 150px;
  background: none !important;
  box-shadow: none !important;
  margin: 0 10px;
  padding: 1rem;
  -webkit-clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
  clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
  min-width: 280px;
  border-radius: 1rem;
  border: 2px solid #0095fa;
}
.select-language input,
.select-language select {
  color: #1f2122 !important;
  border-radius: 10px;
  padding-left: 2rem;
  -webkit-clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
}
.select-language input::placeholder,
.select-language select {
  color: #1f2122;
  font-weight: bolder;
}

.container-btn-wallet {
  display: flex;
  justify-content: flex-end;
}
.container-btn-wallet ul button img {
  opacity: 0;
}
.container-btn-wallet ul:hover button img {
  transition: opacity ease-in-out 1.4s;
  opacity: 1;
}
.container-btn-wallet ul li {
  padding-left: 3rem;
}
.container-btn-wallet ul li img {
  opacity: 0;
}
.container-btn-wallet ul li img:hover {
  transform: scale(1.2);
}
.container-btn-wallet ul:hover li img {
  transition: opacity ease-in-out 1.4s;
  opacity: 1;
}

.btn-wallet {
  position: relative;
  font-size: 18px;
  font-weight: bolder;
  color: #161d2f;
  width: 300px;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all ease 0.3s;
}

.btn-wallet:hover {
  transform: scale(1.01);
}

.div-btn-wallet {
  display: flex;
  justify-content: center;
  padding: 5px;
}

ul {
  padding: 0 !important;
  margin: 0;
  list-style-type: none;
  display: block;
}
ul button img:hover {
  transform: scale(1.2);
}

.text-btn-wallet {
  position: fixed !important;
  color: #ddf2ff;
  font-weight: bolder;
  font-size: 15px;
  padding-top: 10px !important;
  text-align: aling-center;
  text-transform: uppercase;
}

.share-icons {
  position: relative;
  display: flex;
  box-shadow: inset 0 -6px 10px -8px rgba(0, 0, 0, 0.3), inset 0 6px 10px -8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.share-icons__block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  transition: transform 0.3s, filter 0.2s;
  transition-timing-function: ease-in-out;
}
.share-icons__block-left, .share-icons__block-right {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #0095fa;
  transition: 0.6s;
}
.share-icons__block-left {
  clip-path: inset(0 50% 0 0);
  background-image: url("");
}
.share-icons__block-right {
  clip-path: inset(0 0 0 50%);
}
.share-icons__item {
  padding: 0.5rem;
}
.share-icons:hover .share-icons__block {
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
  z-index: -1;
}
.share-icons:hover .share-icons__block-left {
  transform: translateX(-50%) scale(1.2);
}
.share-icons:hover .share-icons__block-right {
  /* move apenas para esquerda transform: translateX(50%) scale(1.2);*/
}

.icon-metamask-btn-wallet {
  width: 30px !important;
  background: none !important;
}

.share-icons__item {
  background: none !important;
  border: none !important;
}

/* responsive*/
@media (max-width: 1500px) {
  .text-title {
    color: #fff !important;
    font-size: 25px;
    text-align: center;
  }
}
@media (max-width: 1560px) {
  .container-btn-wallet {
    height: 100px !important;
  }
  .text-title {
    color: #fff !important;
    font-size: 25px;
    text-align: center;
  }
}
@media (max-width: 750px) {
  .select-language {
    width: 100px !important;
    margin-top: 300px;
    display: none;
  }
  .content-header {
    width: 100%;
  }
  .content-header .content-header {
    padding-top: 300px;
  }
  .content-header .container-btn-wallet {
    justify-content: center;
  }
  .text-title {
    font-size: 20px !important;
    position: fixed;
    left: 50%;
    z-index: 10000022222222;
    top: 0;
  }
}
/*BUTTON join jow*/
.content-home {
  overscroll-behavior-y: none;
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
}

.section-home {
  height: 100vh;
  width: 100%;
}

.content-home::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: smooth;
}

.menu-links {
  position: fixed;
  height: 40%;
  left: 2%;
  top: 30%;
}
.menu-links nav ul li {
  padding: 0.4rem;
  border: 2px solid #0095fa;
  display: flex;
  margin: 1.5rem 0;
  clip-path: polygon(3% 0, 74% 7%, 98% 49%, 92% 96%, 3% 98%, 2% 50%);
}
.menu-links nav ul li a {
  padding: 0.4rem;
  width: 100%;
}

.progress-section {
  position: fixed;
  bottom: 10%;
  left: 90%;
  transform: translateX(-50%);
  width: 60px;
  height: 60%;
  width: 60px;
  will-change: transform;
  transition: 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-galaxy-wrap {
  position: fixed;
  top: 40%;
  right: 28%;
  width: 260px;
  height: 40%;
  width: 60px;
  will-change: transform;
  transition: 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.galaxy-wrap {
  position: relative;
  width: 525px;
  height: 100%;
  overflow: hidden;
}

.progress-bar-wrap {
  position: relative;
  width: 5px;
  height: 100%;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  width: 100%;
  bottom: 0%;
  background: linear-gradient(-380deg, transparent, #0095fa);
}

.progress-rocket {
  position: absolute;
  bottom: 0%;
  width: 80px;
  padding: 1rem;
  border-radius: 50%;
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

.title-section {
  position: fixed;
  font-size: 35px !important;
  width: 50%;
  height: 80px;
  border-radius: 80px;
  top: 170px;
  left: 280px;
}

.btn-joinnow-home {
  margin: 0 auto;
  margin-top: 50px;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
}
.btn-joinnow-home p {
  font-size: 15px;
  margin: auto 0;
}

.logo-home {
  position: fixed;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: 150px;
  left: 50px;
}

.subtitle-section {
  text-align: center;
  font-size: 25px !important;
  width: 60%;
  margin: 0 auto;
  padding-top: 100px;
}

.text-body-home {
  padding-top: 30px;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.currentSectionElement {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  width: 80px;
  height: 80px;
  border-radius: 80px;
  top: 170px;
  left: 240px;
  display: inline-block;
  position: absolute;
  box-shadow: 0 0 120px #0095fa, 0 0 60px #f2ad00, 0 0 10px #c96800, 0 0 200px #feff8f;
  background: radial-gradient(center, ellipse cover, #fcf3a1 0%, #0095fa 100%);
  box-shadow: 0 0 120px #0095fa, 0 0 60px #f2ad00, 0 0 10px #c96800, 0 0 200px #feff8f;
  background: -moz-radial-gradient(center, ellipse cover, #fcf3a1 0%, #0095fa 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #fcf3a1), color-stop(100%, #0095fa));
  background: -webkit-radial-gradient(center, ellipse cover, #fcf3a1 0%, #f1da36 100%);
  background: -o-radial-gradient(center, ellipse cover, #fcf3a1 0%, #f1da36 100%);
  background: -ms-radial-gradient(center, ellipse cover, #fcf3a1 0%, #f1da36 100%);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 25%;
  left: 50%;
  width: 600px;
  height: 600px;
  transform: translate(-50%);
  position: fixed;
  border-radius: 50%;
  display: block;
}

.content-hub-home {
  padding-top: 50px;
  height: 100vh;
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
}
.content-hub-home .rec-arrow-right {
  background: none !important;
  color: #0095fa;
}
.content-hub-home .rec-arrow-left {
  background: none !important;
  color: #0095fa;
}
.content-hub-home .container-hub-home {
  display: flex;
  height: 100%;
}
.content-hub-home .container-hub-home .dropdown-menu {
  background: #0095fa;
  font-weight: bolder;
  box-shadow: inset 0 0 14px -3px rgba(43, 214, 255, 0.4) !important;
  font-family: atmospheric;
}
.content-hub-home .container-hub-home .dropstart {
  width: 100px;
  right: 3%;
  top: 13%;
  position: absolute;
}
.content-hub-home .container-hub-home .dropstart button {
  border: none;
  background: none;
}
.content-hub-home .container-hub-home .dropstart button img {
  width: 40px;
}

.btn-carousel {
  position: relative;
  margin: auto 0;
}
.btn-carousel .wrapper-title-promo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.btn-carousel .wrapper-title-promo h2 {
  font-size: 25px;
  padding-top: 5px;
}
.btn-carousel .wrapper-title-promo button {
  color: white;
  width: 140px;
  margin: 0.5rem;
  background: #0095fa;
  border: none;
}
.btn-carousel .container-promo-hub {
  border: 2px solid #0095fa;
  padding: 1rem;
  position: relative;
  width: 100%;
}
.btn-carousel .container-promo-hub .wrapper-release .text-release {
  position: absolute;
  font-size: 12px !important;
  letter-spacing: 4px;
  font-family: atmospheric;
  top: 5%;
  left: 3%;
  transform: translateY(-50%);
}
.btn-carousel .container-promo-hub video {
  height: 100%;
  width: 100%;
  clip-path: polygon(23% 6%, 29% 0, 100% 0, 100% 100%, 0 100%, 0 6%);
}

.icon-socials-discord {
  bottom: 1%;
  position: absolute !important;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  left: 50%;
  animation: socialsRocketAnimationDiscord 4.3s both;
}

.icon-socials-twitter {
  bottom: 1%;
  position: absolute !important;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  left: 40%;
  animation: socialsRocketAnimationTwitter 5.9s both;
}

.icon-socials-telegram {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  bottom: 1%;
  position: absolute !important;
  left: 60%;
  animation: socialsRocketAnimationTelegram 3s both;
}

.icon-socials-twitter:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

.icon-socials-telegram:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

.icon-socials-discord:hover {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

.btn.focus,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.pushRocketDiscord {
  position: absolute;
  animation: pushRocketButtonAnimation 2s both !important;
  transform: rotateZ(269.5deg);
}

.pushRocketTelegram {
  position: absolute;
  animation: pushRocketButtonAnimation 2s both !important;
  transform: rotateZ(269.5deg);
}

.pushRocketTwitter {
  position: absolute;
  animation: pushRocketButtonAnimation 2s both !important;
  transform: rotateZ(269.5deg);
}

.img-rocket {
  transition: all ease 0.2s;
}

.typerwrite {
  bottom: 0 !important;
  font-size: 30px;
  position: absolute;
  color: white;
}

.icon-socials-text {
  position: absolute !important;
  display: flex;
  align-content: center;
  gap: 2rem;
  left: 15%;
  animation: socialsRocketAnimationText 4.3s both;
}
.icon-socials-text p {
  font-size: 15px;
  font-family: atmospheric;
}
.icon-socials-text img {
  width: 80px;
}

/*animations socials buttons in /hub */
/*push socials buttons animations*/
@keyframes pushRocketButtonAnimation {
  from {
    position: absolute;
    bottom: 0%;
  }
  to {
    bottom: 100%;
    margin-bottom: 400px;
    bottom: 100%;
  }
}
@keyframes socialsRocketAnimationTwitter {
  0% {
    position: absolute;
    left: 0;
    transform: rotateZ(10deg);
  }
  50% {
    left: 40%;
    transform: rotateZ(180deg);
  }
  100% {
    left: 70%;
    transform: rotateZ(269.5deg);
  }
}
@keyframes socialsRocketAnimationDiscord {
  0% {
    position: absolute;
    left: 0;
    transform: rotateZ(10deg);
  }
  50% {
    left: 50%;
    transform: rotateZ(180deg);
  }
  100% {
    left: 50%;
    transform: rotateZ(269.5deg);
  }
}
@keyframes socialsRocketAnimationText {
  0% {
    position: absolute;
    bottom: -15%;
  }
  100% {
    bottom: 1%;
  }
}
@keyframes socialsRocketAnimationTelegram {
  0% {
    position: absolute;
    left: 0;
    transform: rotateZ(10deg);
  }
  50% {
    left: 60%;
    transform: rotateZ(180deg);
  }
  100% {
    left: 60%;
    transform: rotateZ(269.5deg);
  }
}
@keyframes socialsRocketAnimationText {
  0% {
    position: absolute;
    bottom: -15%;
  }
  100% {
    bottom: 3%;
  }
}
@media (max-width: 1760px) {
  @keyframes socialsRocketAnimationDiscord {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 50%;
      transform: rotateZ(180deg);
    }
    100% {
      left: 50%;
      transform: rotateZ(269.5deg);
    }
  }
  @keyframes socialsRocketAnimationTelegram {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 60%;
      transform: rotateZ(180deg);
    }
    100% {
      left: 60%;
      transform: rotateZ(269.5deg);
    }
  }
  @keyframes socialsRocketAnimationTwitter {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 40%;
      transform: rotateZ(180deg);
    }
    100% {
      left: 70%;
      transform: rotateZ(269.5deg);
    }
  }
  .content-hub-home {
    padding-top: 20px;
  }
  .icon-socials-text p {
    font-size: 12px;
  }
  .container-rockets #a {
    width: 80px;
    bottom: 0 !important;
  }
  .btn-carousel {
    position: relative;
    margin: auto 0;
  }
  .btn-carousel .wrapper-title-promo {
    display: flex;
    justify-content: space-between;
  }
  .btn-carousel .wrapper-title-promo h2 {
    font-size: 25px;
  }
  .btn-carousel .wrapper-title-promo button {
    color: white;
    width: 140px;
    margin: 0.5rem;
    background: #0095fa;
    border: none;
  }
  .btn-carousel .wrapper-carousel-promo {
    width: 80%;
  }
}
@media (max-width: 1560px) {
  .container-hub-home {
    height: 75% !important;
  }
}
@media (max-width: 980px) {
  .content-hub-home {
    padding-top: 140px;
  }
  .btn-carousel .wrapper-title-promo h2 {
    font-size: 16px;
  }
  .btn-carousel .container-promo-hub .wrapper-release .text-release {
    font-size: 8px !important;
  }
  .wrapper-carousel-promo {
    width: 100%;
  }
  .icon-socials-text {
    left: 50%;
    transform: translateX(-50%);
  }
  .icon-socials-text p {
    font-size: 8px;
  }
  @keyframes socialsRocketAnimationText {
    0% {
      position: absolute;
      bottom: -15%;
    }
    100% {
      bottom: 0.3%;
    }
  }
  @keyframes socialsRocketAnimationDiscord {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 10%;
      transform: rotateZ(180deg);
    }
    100% {
      left: 16%;
      transform: rotateZ(269.5deg);
    }
  }
  @keyframes socialsRocketAnimationTelegram {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 60%;
      transform: rotateZ(180deg);
    }
    100% {
      left: 70%;
      transform: rotateZ(269.5deg);
    }
  }
  @keyframes socialsRocketAnimationTwitter {
    0% {
      position: absolute;
      left: 0;
      transform: rotateZ(10deg);
    }
    50% {
      left: 20%;
      transform: rotateZ(180deg);
    }
    100% {
      left: 45%;
      transform: rotateZ(269.5deg);
    }
  }
}
@media (max-width: 750px) {
  .icon-socials-telegram,
.icon-socials-discord,
.icon-socials-twitter {
    top: 85%;
  }
  .icon-socials-text {
    width: 80%;
  }
  .icon-socials-text p {
    margin: 0 auto;
  }
  .btn-carousel .wrapper-title-promo h2 {
    font-size: 14px;
  }
  .container-promo-hub {
    padding: 0.2rem !important;
  }
  .container-promo-hub .wrapper-release .text-release {
    font-size: 6px !important;
  }
  .container-promo-hub video {
    width: 100%;
    clip-path: polygon(23% 6%, 29% 0, 100% 0, 100% 100%, 0 100%, 0 6%);
  }
}
.content-live {
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
  min-height: 100vh;
  padding-top: 180px;
  min-height: 100vh;
  width: 100%;
}
.content-live .btn-span-live {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: red;
}
.content-live h1 {
  position: absolute;
  top: 40%;
  transform: translateY(-30%);
  font-size: 50px !important;
  font-family: atmospheric;
  text-align: center;
}
.content-live .container-live {
  width: 70%;
  margin: 0 auto;
}
.content-live .container-live .container-video .video {
  border: 2px solid #0095fa;
  height: 500px;
  border-radius: 1.3rem;
  padding: 1.3rem;
  clip-path: polygon(8% 0, 100% 0, 100% 95%, 69% 100%, 0 100%, 0 9%);
}
.content-live .container-live .wrapper-description-live {
  border: 2px solid #0095fa;
  margin-top: 30px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  clip-path: polygon(0 0, 57% 0, 100% 7%, 100% 100%, 26% 100%, 0 94%);
}
.content-live .btn-pulsar {
  background: none;
  background: #0095fa;
  color: white;
  border: none;
  border-radius: 0.3rem;
  margin-bottom: 10px;
}

#messages {
  background: #272730 !important;
}
#messages .rcw-message-text {
  background: #0095fa !important;
}

.rcw-widget-container {
  position: static !important;
  box-shadow: none !important;
}
.rcw-widget-container .rcw-launcher {
  position: absolute;
  bottom: 4% !important;
  right: 3% !important;
  box-shadow: none !important;
}
.rcw-widget-container .rcw-conversation-container {
  box-shadow: none !important;
}
.rcw-widget-container .rcw-input {
  color: #171720 !important;
}

.content-partners {
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
  padding-top: 100px;
  min-height: 100vh;
  overflow: auto;
}
.content-partners .card-body {
  display: flex;
  transition: all;
  top: 0%;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(23, 23, 47) !important;
}

.container-partners {
  align-items: center;
}
.container-partners .container-info {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  transition: all ease 1s;
}
.container-partners .container-info p {
  transition: all ease 1s;
}

.modal-header {
  display: none !important;
  background-color: none !important;
}

.card {
  border: none !important;
}
.card-img-top {
  width: 200px;
  height: 150px;
}

/* responsive*/
@media (max-width: 750px) {
  .content-partners {
    padding-top: 100px !important;
  }
  .container-partners {
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
  }
}
@keyframes rotateImg {
  from {
    transform: rotate(360);
  }
  to {
    transform: rotate(0);
  }
}
.img-logo-card {
  animation: rotateImg 1s linear;
}

/*.content-hidden {
  display: none;
}
*/
.content-sidebar {
  position: fixed !important;
  height: 100vh;
  width: 6rem !important;
  left: 0;
  top: 10%;
  /*background-color: $bg-card; 

  border-right: 1px solid RGBA(0, 0, 49, 0.9);
  -webkit-box-shadow: 8px 2px 28px -7px rgba(0, 0, 0, 0.25);
  box-shadow: 8px 2px 28px -7px rgba(0, 0, 0, 0.25);
  */
}
.content-sidebar .navbar-nav .nav-item a {
  text-decoration: none !important;
}
.content-sidebar .container-sidebar {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column !important;
  justify-content: space-between;
}
.content-sidebar .container-sidebar .logo-sidebar {
  height: 60px;
  width: 60px !important;
  border-radius: 50%;
  filter: brightness(120%);
}
.content-sidebar .container-sidebar #a {
  padding: 10px 0;
  border-left: 2px solid transparent;
  width: 80px;
  height: 80px;
}
.content-sidebar .container-sidebar #a:focus {
  padding: 10px 0;
  border-top: 2px solid transparent;
  border-left: 2px solid #0095fa;
  width: 80px !important;
  height: 70px !important;
  border-radius: 0 !important;
  height: 70%;
}
.content-sidebar .container-sidebar a {
  width: 80px !important;
  margin: 0 !important;
}
.content-sidebar .container-sidebar a span {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}
.content-sidebar .container-sidebar a img {
  margin: 0 auto !important;
}
.content-sidebar .container-sidebar a:focus {
  background-color: none;
}
.content-sidebar .container-sidebar .icons-sidebar {
  width: 40% !important;
}

.user-container {
  width: 100%;
}

.user {
  display: flex;
}
.user img {
  width: 40%;
}

.user,
.nfts,
.staking,
.hub,
.partners,
.games,
.lives,
.metaverse,
.team,
.market {
  display: flex;
  align-items: center !important;
  justify-content: space-around !important;
}
.user p,
.nfts p,
.staking p,
.hub p,
.partners p,
.games p,
.lives p,
.metaverse p,
.team p,
.market p {
  font-size: 11px;
}

/*responsive*/
@media (max-width: 750px) {
  .btn-dropup {
    width: 70%;
  }
}
/*responsive */
@media (max-width: 750px) {
  .content-sidebar {
    display: none;
  }
  .content-sidebar #a:focus {
    padding: 10px 0;
    width: 80px !important;
    height: 80px !important;
    border-radius: 50% !important;
  }
  .navbar {
    padding: 0 !important;
  }
  .navbar .nav-item {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    display: flex;
  }
  .navbar .nav-item .share-icons {
    margin: 0 auto;
  }
  .navbar .nav-item a {
    margin: 0 auto;
  }
  .navbar .nav-item a p {
    font-family: atmospheric;
  }
  .navbar .navbar-nav {
    margin-top: 60px;
    overflow-y: scroll !important;
    height: 100%;
  }
  .navbar .container-fluid {
    background: #0c2834 !important;
  }
  .navbar .container-fluid .home {
    height: 70px;
    font-size: 14px;
  }
  .navbar .container-fluid .wrapper-logo-sidebar {
    margin: 0 !important;
    background: black;
    border-radius: 50% !important;
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    top: 0 !important;
  }
  .navbar .container-fluid .wrapper-logo-sidebar .logo-sidebar {
    width: 100px !important;
    background: black;
    border-radius: 50% !important;
    -webkit-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
  }
  .navbar #a {
    margin: 0 auto;
  }
}
@media (max-width: 1050px) {
  .content-sidebar {
    display: none;
  }
  .navbar #a span {
    width: 100%;
  }
  .navbar {
    padding: 0 !important;
  }
  .navbar .nav-item {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    display: flex;
  }
  .navbar .nav-item .share-icons {
    margin: 0 auto;
  }
  .navbar .nav-item a {
    margin: 0 auto;
    height: 80px;
    width: 80px;
  }
  .navbar .nav-item a p {
    font-family: atmospheric;
  }
  .navbar .navbar-nav {
    margin-top: 60px;
    overflow-y: scroll !important;
    height: 100%;
  }
  .navbar .navbar-nav .nav-item a {
    height: 70px !important;
    width: 70px !important;
  }
  .navbar .navbar-nav .nav-item a img {
    width: 30px;
  }
  .navbar .container-fluid {
    background: #0c2834 !important;
  }
  .navbar .container-fluid .home {
    height: 70px;
    font-size: 14px;
  }
  .navbar .container-fluid .wrapper-logo-sidebar {
    margin: 0 !important;
    background: black;
    border-radius: 50% !important;
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    top: 0 !important;
  }
  .navbar .container-fluid .wrapper-logo-sidebar .logo-sidebar {
    width: 100px !important;
    background: black;
    border-radius: 50% !important;
    -webkit-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 69px 5px rgba(0, 0, 0, 0.75);
  }
  .navbar #a {
    margin: 0 auto;
  }
}
#navbarText {
  transition: all ease-in-out 0.2s;
}

.content-sidebar-right {
  position: fixed;
  height: 40vh;
  right: 0% !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.container-sidebar-right #a {
  height: 80px !important;
  width: 80px !important;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  justify-content: center;
}
.container-sidebar-right #a span {
  width: 100%;
}

.container-sidebar-right {
  flex-direction: column;
}
.container-sidebar-right .div-btn-sidebar-right .globo {
  width: 40px !important;
  margin: 0 auto !important;
  margin-left: 5px;
}
.container-sidebar-right .div-btn-sidebar-right .dropstart {
  border: none;
  outline: none !important;
  width: 60px;
}
.container-sidebar-right .div-btn-sidebar-right .dropstart button {
  background: none;
  border: none;
  padding: 0 !important;
}
.container-sidebar-right .div-btn-sidebar-right .dropstart img {
  margin-left: 1rem;
  width: 15px;
  position: static;
}
.container-sidebar-right .div-btn-sidebar-right .dropdown-menu {
  background: #0095fa;
  font-weight: bolder;
  box-shadow: inset 0 0 14px -3px rgba(43, 214, 255, 0.4) !important;
  font-family: atmospheric;
}
.container-sidebar-right button {
  margin-bottom: 20px;
  transition: all ease 0.3s;
}

.div-btn-sidebar-right img {
  width: 50%;
}

@media (max-width: 750px) {
  .content-sidebar-right {
    margin-top: 1rem;
    height: 80px !important;
    right: 0% !important;
    width: 100% !important;
    display: flex;
    position: static;
  }
  .content-sidebar-right .container-sidebar-right {
    height: 50px !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0 !important;
  }
  .content-sidebar-right .container-sidebar-right .div-btn-sidebar-right {
    width: 40px;
  }
  .content-sidebar-right .container-sidebar-right .div-btn-sidebar-right button {
    visibility: show !important;
    width: 100%;
  }
}
.content-staking {
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
  padding-top: 40px;
  min-height: 100vh;
}
.content-staking .container-staking {
  width: 80%;
  margin: 0 auto;
}
.content-staking .container-staking .wrapper-text-ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-staking .container-staking .wrapper-text-ranking p {
  color: rgb(65, 171, 65);
}

.content-team {
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
}
.content-team .container-team {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-team .container-team .core-team h1,
.content-team .container-team .comando-team h1 {
  font-family: atmospheric;
  margin: 5rem 0;
}

.nfts h1 {
  position: absolute;
  top: 40%;
  transform: translateY(-30%);
  font-size: 50px !important;
  font-family: atmospheric;
  text-align: center;
}

@media (max-width: 750px) {
  .content-team {
    padding-top: 100px;
  }
}
/*var colors*/
#a-table {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  height: 100%;
  color: #ddf2ff;
  text-transform: uppercase;
  text-decoration: none;
  overflow: hidden;
  transition: 0.3s;
}

#a-table:hover {
  /* background: #39ff14;
  box-shadow: 0 0 10px #39ff14, 0 0 40px #39ff14, 0 0 80px #39ff14;
  */
  transition-delay: 0.85s;
}

#a-table:focus {
  background-color: #14b1ff;
}

#a-table span {
  position: absolute;
  display: block;
}

/* parte de cima da animação */
#a-table span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #14b1ff);
}

#a-table:hover span:nth-child(1) {
  left: 100%;
  transition: 0.6s;
}

/* parte de baixo da animação */
#a-table span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #14b1ff);
}

#a-table:hover span:nth-child(3) {
  right: 100%;
  transition: 0.8s;
  transition-delay: 0.5s;
}

/* parte da direita da animação */
#a-table span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #14b1ff);
}

#a-table:hover span:nth-child(2) {
  top: 100%;
  transition: 0.8s;
  transition-delay: 0.2s;
}

/* parte da esquerda da animação */
#a-table span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #14b1ff);
}

#a-table:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.8s;
  transition-delay: 0.7s;
}

/*class b, a mesma coisa que A porém, nao tem evento focus, e background após click*/
.content-startups {
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
  padding-top: 50px;
  min-height: 100vh;
}
.content-startups .container-startups {
  margin: 0 auto;
}
.content-startups .container-startups .btn-demo {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  width: 80px;
  height: 30px;
  background: #0095fa;
  border: none;
}
.content-startups .container-startups .btn-demo p {
  margin: auto 0;
  text-align: center;
  font-size: 14px;
  color: white;
}

@media (max-width: 750px) {
  .apresentation-startups {
    font-size: 14px;
    margin: 0 auto;
    text-align: center !important;
  }
  .content-startups {
    padding-top: 150px;
  }
}
.content-ranking .header-ranking {
  margin-top: 0.5rem;
  font-size: 18px;
  font-family: atmospheric;
}
.content-ranking .container-ranking {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  height: 484px;
  display: block;
  align-items: center;
  border: 2px solid #0095fa;
  border-radius: 1rem;
  clip-path: polygon(71% 0, 100% 0, 100% 100%, 0 100%, 0 72%, 1% 59%, 1% 39%, 0 20%, 0 0);
}
.content-ranking .container-ranking .item-ranking {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
  padding-bottom: 1rem;
}
.content-ranking .container-ranking .item-ranking .wrapper-text-ranking {
  display: flex;
  flex-direction: column;
}
.content-ranking .container-ranking .item-ranking .wrapper-text-ranking .volume-coin {
  color: #7cc91f;
  font-weight: bold;
}
.content-ranking .container-ranking .item-ranking img {
  border: 4px solid #0095fa;
  width: 100px;
  border-radius: 1rem;
}

@media (max-width: 750px) {
  .container-ranking {
    width: 70%;
    margin: 0 auto;
  }
}
@media (max-width: 1200px) {
  .container-ranking {
    width: 100%;
    margin: 0 auto;
  }
}
.rec-carousel-wrapper {
  margin-bottom: 200px;
  margin: 0 !important;
}

.container-carousel {
  margin-top: 3% auto;
  width: 100%;
}

.title-inset-carousel {
  font-size: 20px;
  left: 3%;
  top: 3%;
  position: absolute;
  font-weight: bolder;
  text-transform: uppercase;
  font-family: atmospheric;
  transform: translateX(-50%);
  transform: translateY(-50%);
}

.hidden-pagination {
  display: none !important;
}

.rec.rec-item-wrapper .info-card {
  border: 2px solid #0095fa;
  margin: 10px 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 1rem;
}
.rec.rec-item-wrapper .info-card .wrapper-name-game {
  margin: 20px 0 0 0 !important;
  padding: 0 !important;
}
.rec.rec-item-wrapper .info-card .wrapper-name-game p {
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
}
.rec.rec-item-wrapper .info-card .wrapper-icon-price {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 6px auto 0 !important;
  padding: 0 !important;
}
.rec.rec-item-wrapper .info-card .wrapper-icon-price img {
  width: 20px;
  height: 20px;
  margin-bottom: 18px;
}

.img-carousel {
  padding: 1rem;
  clip-path: polygon(0% 40px, 40px 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0px 100%);
  min-width: 280px;
  border-radius: 1rem;
  border: 2px solid #0095fa;
}

.rec.rec-dot {
  border: 1px solid #14b1ff !important;
  background-color: none;
}

.carousel-games {
  position: relative;
  padding-top: 5rem;
}
.carousel-games .rec-arrow-right {
  position: absolute;
  top: 2% !important;
  right: 2%;
  background: none !important;
  color: #0095fa;
  transform: translateX(-50%);
  transform: translateY(-50%);
}
.carousel-games .rec-arrow-left {
  position: absolute;
  top: 2% !important;
  right: 7%;
  background: none !important;
  color: #0095fa;
  transform: translateX(-50%);
  transform: translateY(-50%);
}
.carousel-games .rec-arrow-left:hover {
  box-shadow: inset 0 0 14px -3px rgba(43, 214, 255, 0.4);
}
.carousel-games .rec-arrow-right:hover {
  box-shadow: inset 0 0 14px -3px rgba(43, 214, 255, 0.4) !important;
}
.carousel-games .rec-slider-container {
  padding: 0 !important;
  width: 100%;
}

@media (max-width: 750px) {
  .container-carousel {
    margin: 0 auto;
  }
  .container-carousel h1 {
    margin-top: 30px;
  }
  .container-carousel .img-carousel {
    margin-top: 50px;
  }
}
@media (max-width: 480px) {
  .rec-carousel-wrapper {
    padding-top: 0.2rem !important;
    margin-bottom: 200px;
    margin: 0 !important;
  }
  .carousel-games {
    position: relative;
  }
  .carousel-games .rec-arrow-right {
    position: static;
  }
  .carousel-games .rec-arrow-left {
    position: static;
  }
  .carousel-games .rec-arrow-left:hover {
    box-shadow: inset 0 0 14px -3px rgba(43, 214, 255, 0.4);
  }
  .carousel-games .rec-arrow-right:hover {
    box-shadow: inset 0 0 14px -3px rgba(43, 214, 255, 0.4) !important;
  }
  .carousel-games .rec-slider-container {
    padding: 0 !important;
    width: 100%;
  }
}
@media (max-width: 1380px) {
  .img-carousel {
    padding: 1rem;
    clip-path: polygon(0% 40px, 40px 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0px 100%);
    min-width: 180px;
    border-radius: 1rem;
    border: 2px solid #0095fa;
  }
}
.content-loader {
  transition: all ease-out 0.3s;
  position: fixed;
  width: 100vw;
  z-index: 10000000;
  align-items: center;
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding: 4rem 20px;
}

.loading {
  display: flex;
  justify-content: space-between;
  max-width: 72px;
  margin: 0 auto;
  width: 100%;
}

.dot {
  animation: loading 1s cubic-bezier(0.55, -0.77, 0.42, 1.79) 0s infinite normal both running;
  background: #0095fa;
  border-radius: 50%;
  display: block;
  height: 1rem;
  width: 1rem;
}
.dot:nth-child(2) {
  animation-name: loading-2;
}
.dot:nth-child(3) {
  animation-name: loading-3;
}

@keyframes loading {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(22%);
  }
  25% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
}
@keyframes loading-2 {
  25% {
    transform: translateY(0);
  }
  29% {
    transform: translateY(22%);
  }
  50% {
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(0);
  }
}
@keyframes loading-3 {
  50% {
    transform: translateY(0);
  }
  54% {
    transform: translateY(22%);
  }
  75% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.container-allgames {
  height: 100vh;
}
.container-allgames h1 {
  font-size: 30px;
  margin: 5rem 0 8rem 0;
  font-family: atmospheric;
}

.container-border-light {
  padding-top: 20px;
}

.background-img {
  height: 400px;
  background-repeat: no-repeat;
  border: 1px solid #2a3cad;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #2a3cad;
  position: relative;
}

.content h2 {
  font-size: 19px;
}

.box {
  position: absolute;
  top: 50%;
  display: f;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  overflow: hidden;
  color: white;
}

.box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.4s;
  pointer-events: none;
}

.box:hover:before {
  left: 100%;
  transform: skewX(-15deg);
}

.box .content {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  padding: 20px;
  text-align: center;
}
.box .content img {
  border-radius: 1rem;
  width: 80%;
}

.box span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.box span:nth-child(1) {
  transform: rotate(0deg);
}

.box span:nth-child(2) {
  transform: rotate(90deg);
}

.box span:nth-child(3) {
  transform: rotate(180deg);
}

.box span:nth-child(4) {
  transform: rotate(270deg);
}

.box span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #50dfdb;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}
@media (max-width: 1360px) {
  .container-border-light .background-img .box {
    width: 230px;
    height: 230px;
  }
}
@media (max-width: 1180px) {
  .container-border-light .background-img .box {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 750px) {
  .container-border-light .background-img .box {
    width: 270px;
    height: 270px;
  }
}
@media (max-width: 380px) {
  .container-border-light .background-img .box {
    width: 220px;
    height: 220px;
  }
}
.body-btn-span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  display: flex;
  height: 100%;
  margin: auto 40px;
}

.btn-span {
  display: flex;
  text-decoration: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  animation: pulse 2s ease-out infinite;
}

span {
  color: transparent;
  text-shadow: 0 0 0 white;
}

.btn::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(0, 210, 255), 0 0 0 0px rgba(0, 210, 255, 0.85);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 210, 255, 0), 0 0 0 30px rgba(0, 210, 255, 0);
  }
}
.span-large {
  padding: 1rem;
  width: 80%;
  font-size: 2rem;
  border-radius: 0 !important;
}

.span-medium {
  padding: 0.8rem;
  width: 60%;
  font-size: 1.2rem;
}

.span-small {
  width: 40%;
  font-size: 0.9rem;
  border-radius: 0 !important;
}
.span-small img {
  width: 120%;
}

.body-btn-span-magenta {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  display: flex;
  height: 100%;
  margin: auto 40px;
}

.btn-span-magenta {
  display: flex;
  text-decoration: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  animation: pulse-magenta 2s ease-out infinite;
}

span {
  color: transparent;
  text-shadow: 0 0 0 white;
}

.btn::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
}

@keyframes pulse-magenta {
  0% {
    box-shadow: 0 0 0 0px rgb(255, 0, 98), 0 0 0 0px rgba(255, 0, 98, 0.85);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 0, 98, 0), 0 0 0 30px rgba(255, 0, 98, 0);
  }
}
.span-large-magenta {
  padding: 1rem;
  width: 80%;
  font-size: 2rem;
  border-radius: 0 !important;
}

.span-medium-magenta {
  padding: 0.8rem;
  width: 60%;
  font-size: 1.2rem;
}

.span-smal-magental {
  width: 40%;
  font-size: 0.9rem;
  border-radius: 0 !important;
}
.span-smal-magental img {
  width: 120%;
}

.content-gameform {
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
  min-height: 100vh;
  padding-top: 50px;
  overflow: auto !important;
}
.content-gameform .container-gameform {
  height: 100%;
}
.content-gameform .container-gameform .card-posts {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 220px;
  width: 280px;
  background: none !important;
  box-shadow: 200px 3px 129px -81px rgba(0, 0, 0, 0.45) inset;
  -webkit-box-shadow: 200px 3px 129px -81px rgba(0, 0, 0, 0.45) inset;
  -moz-box-shadow: 200px 3px 129px -81px rgba(0, 0, 0, 0.45) inset;
  border: 2px solid #0095fa;
  border-radius: 0.5rem;
  text-decoration: none;
  margin-bottom: 20px;
  clip-path: polygon(0% 0, 40px 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0px 100%);
}
.content-gameform .container-gameform .card-posts img {
  width: 100%;
  filter: brightness(80%);
}
.content-gameform .container-gameform .card-posts wrapper-socials-newslatest img:hover {
  width: 100%;
  filter: brightness(100%);
}
.content-gameform .container-gameform .card-posts .card-title-posts {
  font-size: 15px;
  background: none;
  text-align: center;
  text-decoration: none !important;
}
.content-gameform .container-gameform .card-posts .card-body-posts {
  font-size: 30px;
  padding: 1rem;
  border-radius: 0.5rem;
}
.content-gameform .container-gameform .card-posts .card-body-posts .wrapper-creator-date-post {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.content-gameform .container-gameform .card-posts .card-body-posts .wrapper-creator-date-post img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.content-gameform .container-gameform .card-posts .card-body-posts .wrapper-creator-date-post small {
  font-size: 13px;
}
.content-gameform .wrapper-socials-newslatest #a {
  width: 60px;
  height: 60px;
}
.content-gameform .wrapper-socials-newslatest #a span {
  width: 100%;
}
.content-gameform .wrapper-socials-newslatest {
  border: 2px solid #0095fa;
  clip-path: polygon(9% 0, 100% 0, 100% 91%, 92% 100%, 0 100%, 0 9%);
  border-radius: 1rem;
  display: flex;
  margin-bottom: 50px;
}
.content-gameform .wrapper-socials-newslatest p {
  text-align: center;
  padding: 20px 0 0;
  margin: 0 !important;
}
.content-gameform .wrapper-socials-newslatest button {
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
  border: none !important;
}
.content-gameform .wrapper-socials-newslatest button img {
  width: 30px;
}

.mini-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  margin: 0.5rem auto;
  margin-bottom: 4rem;
  height: 100px;
}
.mini-menu .metaverse {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mini-menu #a {
  padding-top: 20px;
  justify-content: center;
}
.mini-menu #a p {
  font-size: 18px;
}
.mini-menu #a img {
  margin: auto;
  width: 20px;
}
.mini-menu a {
  margin: 0 !important;
}
.mini-menu a span {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}
.mini-menu a img {
  margin: 0 auto !important;
}

@media (max-width: 750px) {
  .content-gameform {
    padding-top: 100px !important;
  }
  .wrapper-socials-newslatest {
    padding-bottom: 10px;
  }
  .wrapper-socials-newslatest p {
    padding-top: 10px !important;
  }
  .wrapper-socials-newslatest button {
    width: 30px !important;
    height: 30px !important;
  }
  .wrapper-socials-newslatest button img {
    width: 25px;
  }
  .mini-menu {
    margin-bottom: 0.5rem;
  }
  .mini-menu #a {
    height: 80px;
  }
  .mini-menu #a p {
    font-size: 12px;
  }
}
.crypto_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  width: 40px;
  height: 40px;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: #eee;
  border-color: #eee;
}

.pagination button.active {
  font-weight: 900;
  border-color: #0095fa;
  background: #0095fa;
  color: white;
}

.img-table {
  width: 30px;
}

.input-table {
  margin: 0 10px;
  padding: 1rem;
  -webkit-clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
  clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
  min-width: 280px;
  border-radius: 1rem;
  border: 2px solid #0095fa;
}
.input-table input,
.input-table select {
  color: #1f2122 !important;
  border-radius: 10px;
  padding-left: 2rem;
  -webkit-clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
}
.input-table input::placeholder,
.input-table select {
  color: #1f2122;
  font-weight: bolder;
}

.content-post {
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  display: 100%;
  padding-top: 50px;
  min-height: 100vh;
}
.content-post .container-post {
  margin: 0 auto;
}
.content-post .container-post .wrapper-post .post-title {
  font-size: 2.8rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-weight: bolder;
}
.content-post .container-post .wrapper-post .subtitle-post {
  font-size: 1.5rem;
  font-weight: bold;
}
.content-post .container-post .wrapper-post .conclusion-post,
.content-post .container-post .wrapper-post .intro-post,
.content-post .container-post .wrapper-post .body-post {
  font-size: 1.4rem;
}
.content-post .container-post .wrapper-post .banner-post {
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.content-post .container-post .wrapper-post .wrapper-date-avatar {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin: 1rem 0;
}
.content-post .container-post .wrapper-post .wrapper-date-avatar p {
  margin-top: 20px;
}
.content-post .container-post .wrapper-post .wrapper-date-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media (max-width: 750px) {
  .content-post {
    padding-top: 100px;
  }
  .post-date {
    font-size: 12px;
  }
  .banner-post {
    width: 100%;
  }
  .post-title {
    margin: 0 !important;
    font-size: 20px !important;
  }
  .mini-menu {
    justify-content: space-between;
    padding: 0 !important;
  }
}
.content-market {
  min-height: 100vh;
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
}
.content-market .container-market {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.content-market .container-market h1 {
  position: absolute;
  top: 40%;
  transform: translateY(-30%);
  font-size: 50px !important;
  font-family: atmospheric;
  text-align: center;
}

.crypto_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  width: 40px;
  height: 40px;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: #eee;
  border-color: #eee;
}

.pagination button.active {
  font-weight: 900;
  border-color: #0095fa;
  background: #0095fa;
  color: white;
}

.img-table {
  width: 30px;
}

.input-table {
  margin: 0 10px;
  padding: 1rem;
  -webkit-clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
  clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
  min-width: 280px;
  border-radius: 1rem;
  border: 2px solid #0095fa;
}
.input-table input,
.input-table select {
  color: #1f2122 !important;
  border-radius: 10px;
  padding-left: 2rem;
  -webkit-clip-path: polygon(0 40px, 40px 0, 100% 0, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0 100%);
}
.input-table input::placeholder,
.input-table select {
  color: #1f2122;
  font-weight: bolder;
}

.content-gameinfo {
  min-height: 100vh;
  padding-top: 180px;
  background: radial-gradient(at 50% 100%, #272730 40%, #171720 80%, #070710 100%);
  width: 100%;
  min-height: 100vh;
}
.content-gameinfo .container-gameinfo #a span {
  width: 100%;
}
.content-gameinfo .container-gameinfo {
  width: 70%;
  margin: 0 auto;
}
.content-gameinfo .container-gameinfo .titile-gameinfo {
  font-size: 25px;
  font-family: atmospheric;
  text-align: center;
  margin-top: 20px;
}
.content-gameinfo .container-gameinfo .wrapper-categories {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-family: atmospheric;
}
.content-gameinfo .container-gameinfo .wrapper-categories p {
  margin: 0 auto;
  margin-top: 1rem;
  font-size: 13px;
}
.content-gameinfo .container-gameinfo .container-header-gameinfo {
  border: 2px solid #0095fa;
  clip-path: polygon(8% 0, 100% 0, 100% 91%, 90% 100%, 0 100%, 0 8%);
  border-radius: 1rem;
}
.content-gameinfo .container-gameinfo .container-header-gameinfo img {
  width: 30px;
  height: 30px;
}
.content-gameinfo .container-gameinfo .container-header-gameinfo .text-plarforms {
  font-size: 10px;
}
.content-gameinfo .container-gameinfo .container-header-gameinfo .text-categories,
.content-gameinfo .container-gameinfo .container-header-gameinfo .text-platforms {
  font-family: atmospheric;
}
.content-gameinfo .container-gameinfo .container-sidebar-gamefinfo {
  border: 2px solid #0095fa;
  border-radius: 1rem;
  padding: 2rem;
  clip-path: polygon(0 0, 66% 0, 100% 13%, 100% 100%, 12% 100%, 0 87%);
}
.content-gameinfo .container-gameinfo .container-sidebar-gamefinfo .img-platform {
  width: 40px;
}
.content-gameinfo .container-gameinfo .title-rating {
  font-family: atmospheric;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  font-size: 20px;
  margin-top: 1rem;
}
.content-gameinfo .container-gameinfo .wrapper-items-gameinfo {
  padding: 0 !important;
  border-radius: 2rem;
  clip-path: polygon(22% 0, 100% 0, 100% 35%, 100% 75%, 75% 100%, 52% 100%, 29% 100%, 0 100%, 0% 35%, 0 22%);
  border: 2px solid #0095fa;
  border-radius: 1rem;
}
.content-gameinfo .container-gameinfo .wrapper-socials-gameinfo {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.content-gameinfo .container-gameinfo .wrapper-socials-gameinfo button {
  height: 80px !important;
  width: 80px !important;
  font-size: 12px !important;
}
.content-gameinfo .container-gameinfo .wrapper-socials-gameinfo button img {
  width: 25px;
}

.wrapper-sidebar-gameinfo {
  display: flex;
  gap: 1rem;
}
.wrapper-sidebar-gameinfo img {
  width: 20px;
  height: 20px;
}

.btn-clip-path:hover {
  box-shadow: inset 0 0 14px -3px rgba(43, 214, 255, 0.4);
}

.content-footer {
  height: 80px !important;
}
.content-footer ul li a img {
  width: 25px;
}

.container-border-light-gamejam {
  padding-top: 20px;
}

.background-img-gamejam {
  height: 400px;
  background-repeat: no-repeat;
  border: 1px solid #ca4932;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #8e2261;
  position: relative;
}

.content-gamejam h2 {
  font-size: 19px;
}

.box-gamejam {
  position: absolute;
  top: 50%;
  display: f;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  box-sizing: border-box;
  overflow: hidden;
  color: white;
}

.box-gamejam:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.4s;
  pointer-events: none;
}

.box-gamejam:hover:before {
  left: 100%;
  transform: skewX(-15deg);
}

.box-gamejam .content-gamejam {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  padding: 20px;
  text-align: center;
}
.box-gamejam .content-gamejam img {
  border-radius: 1rem;
  width: 80%;
}

.box-gamejam span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.box-gamejam span:nth-child(1) {
  transform: rotate(0deg);
}

.box-gamejam span:nth-child(2) {
  transform: rotate(90deg);
}

.box-gamejam span:nth-child(3) {
  transform: rotate(180deg);
}

.box-gamejam span:nth-child(4) {
  transform: rotate(270deg);
}

.box-gamejam span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #a04c46;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}
@media (max-width: 1360px) {
  .container-border-light-gamejam .background-img-gamejam .box-gamejam {
    width: 230px;
    height: 230px;
  }
}
@media (max-width: 1180px) {
  .container-border-light-gamejam .background-img-gamejam .box-gamejam {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 750px) {
  .container-border-light-gamejam .background-img-gamejam .box-gamejam {
    width: 270px;
    height: 270px;
  }
}
@media (max-width: 380px) {
  .container-border-light-gamejam .background-img-gamejam .box-gamejam {
    width: 220px;
    height: 220px;
  }
}
.container-allgames {
  height: 100vh;
}
.container-allgames h1 {
  font-size: 30px;
  margin: 5rem 0 8rem 0;
  font-family: atmospheric;
}