$color-primary: #0095fa;
.content-gameform {
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  width: 100%;
  min-height: 100vh;
  padding-top: 50px;
  overflow: auto !important;
  .container-gameform {
    height: 100%;
    .card-posts {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      width: 220px;
      width: 280px;
      background: none !important;
      box-shadow: 200px 3px 129px -81px rgba(0, 0, 0, 0.45) inset;
      -webkit-box-shadow: 200px 3px 129px -81px rgba(0, 0, 0, 0.45) inset;
      -moz-box-shadow: 200px 3px 129px -81px rgba(0, 0, 0, 0.45) inset;
      border: 2px solid $color-primary;
      border-radius: 0.5rem;
      text-decoration: none;
      margin-bottom: 20px;
      clip-path: polygon(
        0% 0,
        $notchSize 0%,
        100% 0%,
        100% calc(100% - #{$notchSize}),
        calc(100% - #{$notchSize}) 100%,
        0px 100%
      );

      img {
        width: 100%;
        filter: brightness(80%);
      }
      wrapper-socials-newslatest img:hover {
        width: 100%;
        filter: brightness(100%);
      }
      .card-title-posts {
        font-size: 15px;
        background: none;
        text-align: center;
        text-decoration: none !important;
      }

      .card-body-posts {
        font-size: 30px;

        padding: 1rem;
        border-radius: 0.5rem;

        .wrapper-creator-date-post {
          display: flex;
          align-items: center;
          gap: 1rem;
          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }
          small {
            font-size: 13px;
          }
        }
      }
    }
  }

  .wrapper-socials-newslatest #a {
    width: 60px;
    height: 60px;
    span {
      width: 100%;
    }
  }
  .wrapper-socials-newslatest {
    border: 2px solid $color-primary;
    clip-path: polygon(9% 0, 100% 0, 100% 91%, 92% 100%, 0 100%, 0 9%);
    border-radius: 1rem;
    display: flex;
    margin-bottom: 50px;
    p {
      text-align: center;
      padding: 20px 0 0;
      margin: 0 !important;
    }

    button {
      padding: 0 !important;
      margin: 0 !important;
      background: none !important;
      border: none !important;
      img {
        width: 30px;
      }
    }
  }
}

.mini-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 1rem;
  //border: 2px solid $color-primary;
  padding: 1rem;
  width: 100%;
  margin: 0.5rem auto;
  margin-bottom: 4rem;

  .metaverse {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #a {
    padding-top: 20px;

    justify-content: center;

    p {
      font-size: 18px;
    }
    img {
      margin: auto;
      width: 20px;
    }
  }
  height: 100px;

  a {
    margin: 0 !important;

    span {
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
    }
    img {
      margin: 0 auto !important;
    }
  }
}

//responsividade

@media (max-width: 750px) {
  .content-gameform {
    padding-top: 100px !important;
  }

  .wrapper-socials-newslatest {
    padding-bottom: 10px;
    p {
      padding-top: 10px !important;
    }

    button {
      width: 30px !important;
      height: 30px !important;

      img {
        width: 25px;
      }
    }
  }

  .mini-menu {
    margin-bottom: 0.5rem;

    #a {
      height: 80px;
      p {
        font-size: 12px;
      }
    }
  }
}
