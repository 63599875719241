/*BUTTON join jow*/
$body-bg: linear-gradient(180deg, #1c0138 0%, #2c0055 100%);
$border: linear-gradient(180deg, #ffdc2a 0%, #fb2182 41.54%);
$radius: 3rem;
$color-primary: #0095fa;
$notchSize: 40px;

.content-home {
  overscroll-behavior-y: none;
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
}

.section-home {
  height: 100vh;
  width: 100%;
}

#section1 {
}

.content-home::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar {
  display: smooth;
}

.menu-links {
  position: fixed;
  height: 40%;
  left: 2%;
  top: 30%;
  nav {
    ul {
      li {
        padding: 0.4rem;
        border: 2px solid $color-primary;
        display: flex;
        margin: 1.5rem 0;
        clip-path: polygon(3% 0, 74% 7%, 98% 49%, 92% 96%, 3% 98%, 2% 50%);

        a {
          padding: 0.4rem;
          width: 100%;
        }
      }
    }
  }
}

.progress-section {
  position: fixed;
  bottom: 10%;
  left: 90%;
  transform: translateX(-50%);
  width: 60px;
  height: 60%;
  width: 60px;
  will-change: transform;
  transition: 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-galaxy-wrap {
  position: fixed;
  top: 40%;
  right: 28%;
  width: 260px;
  height: 40%;
  width: 60px;
  will-change: transform;
  transition: 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.galaxy-wrap {
  position: relative;
  width: 525px;
  height: 100%;

  overflow: hidden;
}
.progress-bar-wrap {
  position: relative;
  width: 5px;
  height: 100%;

  overflow: hidden;
}

.progress-bar {
  position: absolute;

  width: 100%;
  bottom: 0%;
  background: linear-gradient(-380deg, transparent, $color-primary);
}
.progress-rocket {
  position: absolute;
  bottom: 0%;
  width: 80px;
  padding: 1rem;
  border-radius: 50%;
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
}

.title-section {
  position: fixed;
  font-size: 35px !important;
  width: 50%;
  height: 80px;
  border-radius: 80px;
  top: 170px;
  left: 280px;
}

.btn-joinnow-home {
  margin: 0 auto;
  margin-top: 50px;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  p {
    font-size: 15px;
    margin: auto 0;
  }
}

.logo-home {
  position: fixed;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: 150px;
  left: 50px;
}

.subtitle-section {
  text-align: center;
  font-size: 25px !important;
  width: 60%;
  margin: 0 auto;
  padding-top: 100px;
}

.text-body-home {
  padding-top: 30px;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.currentSectionElement {
  -webkit-box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  box-shadow: inset 0px 0px 14px -3px rgba(43, 214, 255, 0.4);
  width: 80px;
  height: 80px;
  border-radius: 80px;
  top: 170px;
  left: 240px;
  display: inline-block;
  position: absolute;
  box-shadow: 0 0 120px $color-primary, 0 0 60px #f2ad00, 0 0 10px #c96800,
    0 0 200px #feff8f;
  background: radial-gradient(
    center,
    ellipse cover,
    #fcf3a1 0%,
    $color-primary 100%
  );
  box-shadow: 0 0 120px $color-primary, 0 0 60px #f2ad00, 0 0 10px #c96800,
    0 0 200px #feff8f;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #fcf3a1 0%,
    $color-primary 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #fcf3a1),
    color-stop(100%, $color-primary)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #fcf3a1 0%,
    #f1da36 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #fcf3a1 0%,
    #f1da36 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #fcf3a1 0%,
    #f1da36 100%
  );
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 25%;
  left: 50%;
  width: 600px;
  height: 600px;
  transform: translate(-50%);
  position: fixed;
  border-radius: 50%;
  display: block;
}
