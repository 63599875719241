$text-color-white: #ddf2ff;
$bg-card: #236da9;
$primary: #0095fa;

.text-name-card {
  text-align: center;
  font-weight: 400;
  margin: 8px 0;
}

.text-name-card-team {
  color: rgb(217, 204, 204);
  text-align: center;
  letter-spacing: 3px;
  font-size: 24px;

  margin: 10px 0;
}

.cart-container {
  width: 600px !important;
  display: block;
}

.nft {
  cursor: pointer;
  user-select: none;
  width: 300px;
  margin: 1rem auto;
  border: 1px solid #0cb60022;
  background-color: $primary;
  background: linear-gradient(
    0deg,
    rgb(6, 7, 38) 0%,
    rgba(55, 2, 102, 0.3) 100%
  );
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: 0.7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: 0.5s all;
  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin-top: 0;
  }
  ins {
    text-decoration: none;
  }
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    .container-img-card {
      max-width: 100%;
      height: 250px;

      .img-team {
        border-radius: 50%;

        top: 10.3%;
        left: 16%;
        z-index: 1;
        width: 70%;
        border: 2px solid rgb(70, 10, 134);

        filter: brightness(80%);
        position: absolute !important;
        opacity: 1;
        transition: opacity ease-in-out 0.5s;
      }

      .img-nft {
        border-radius: 50%;
        border: 2px solid rgb(70, 10, 134);
        top: 10.3%;
        left: 16%;
        position: absolute;
        width: 70%;
        transition: opacity ease 0.5s;
      }
    }
    .img-team:hover {
      opacity: 0;
      animation: float 6s ease-in-out infinite;
    }

    .description {
      margin: 0.5rem 0;
      color: #a89ec9;
    }
    .tokenInfo {
      display: flex;

      justify-content: space-between;
      align-items: center;
      .price {
        display: flex;
        font-size: 13px;
        align-items: center;
        color: #ee83e5;
        font-weight: 400;
        ins {
          margin-left: -0.3rem;
          margin-right: 0.5rem;
        }
      }
      .duration {
        display: flex;
        align-items: center;
        color: #a89ec9;
        margin-right: 0.2rem;
        font-size: 12px;
        ins {
          margin: 0.5rem;
          margin-bottom: 0.4rem;
        }
      }
    }
    .creator {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      margin-bottom: -0.3rem;
      ins {
        color: #a89ec9;
        text-decoration: none;
      }
      .wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #ffffff22;
        padding: 0.3rem;
        margin: 0;
        margin-right: 0.5rem;
        border-radius: 100%;
        box-shadow: 0 0 0 4px #1a86a7aa;
        img {
          border-radius: 100%;
          border: 1px solid #ffffff22;
          width: 2rem;
          height: 2rem;
          object-fit: cover;
          margin: 0;
          animation: spiner-img-card 0.5s both;
        }

        @keyframes spiner-img-card {
          from {
            transform: rotate(-760deg);
          }
          to {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  ::before {
    position: fixed;

    content: '';
    box-shadow: 0 0 100px 40px #ffffff08;
    top: -10%;
    left: -100%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: 0.8s all;
  }
  &:hover {
    position: relative;
    z-index: 1000;
    border: 1px solid $primary;
    box-shadow: 0 3px 5px 1px $bg-card;
    transform: scale(1.015);

    ::before {
      filter: brightness(0.5);
      top: -100%;
      left: 200%;
    }
  }
}

.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    font-size: 20rem;
    filter: opacity(0.5);
  }
}

.img-card-partners {
  width: 100%;
  margin: 0 auto;
}

/*responsive*/

@media (max-width: 750px) {
  .container-cards {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}
