.content-footer {
  height: 80px !important;

  ul {
    li {
      a {
        img {
          width: 25px;
        }
      }
    }
  }
}
