/*var colors*/
$notchSize: 40px;
$text-color-white: #ddf2ff;

$bg-content: #1f2122;
$bg-container: #27292a;
$color-primary: #0095fa;

.content-games {
  padding-top: 50px;
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  min-height: 100vh;
  width: 100%;

  .icon-crown-rating {
    width: 40px;
    margin-bottom: 10px;
  }

  .icon-best-rating {
    width: 30px;
    height: 30px;
  }

  .title-top-6 {
    font-family: atmospheric;
    margin: 300px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    img {
      margin-bottom: 1rem;
      width: 60px;
      height: 60px;
    }
  }
}
.link-view-allgames {
  margin-top: 7rem;
}
.container-games {
  margin: 0 auto;
  overflow: hidden !important;
}

.wrapper-title-section {
  width: 100%;
}

.wrapper-posts {
  height: 400%;
}

.text-games {
  margin-top: 100px;
  font-size: 90px !important;
  font-weight: bolder;
  color: white !important;
  text-align: center !important;
}

.container-filter {
  height: 400px;
  overflow: auto;
  display: flex;
  width: 100%;
}

.container-filter-games {
  width: 100%;
}

.btn-clear {
  color: white;
  width: 80%;
  margin-left: 15px;
  width: 70px;
  background: none;
  border: none;
}

.card-best-rating {
  border: 2px solid $color-primary;
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
  clip-path: polygon(
    0% $notchSize,
    $notchSize 0%,
    100% 0%,
    100% calc(100% - #{$notchSize}),
    calc(100% - #{$notchSize}) 100%,
    0px 100%
  );

  display: flex;
  .info-card {
    margin: 0 auto;

    .wrapper-name-game {
      div {
        .icon-best-rating {
          margin: 10px 0 !important;
        }
        p {
          margin: 0 !important;
        }
      }
    }
  }
}

.icon-trash {
  width: 25px;
  margin-right: 10px;

  background-color: none;
}

.tr {
  margin: 5px !important;
}
/*responsive*/

@media (max-width: 1500px) {
  .container-games {
    overflow: auto;
    margin: 0 auto !important;
  }
}

@media (max-width: 950px) {
  .btn-clear {
    img {
      width: 20px;
    }
    p {
      font-size: 10px;
    }
  }
}

@media (max-width: 750px) {
  .title-top-6 {
    display: flex;
    p {
      font-size: 18px !important;
    }
    img {
      width: 70px !important;
      margin: 0 !important;
    }
  }
  .container-best-games {
    width: 70%;
    margin: 0 auto;
  }
  .content-games {
    padding-top: 120px;
    .title-inset-carousel {
      left: 40%;
      left: 50%;
    }
  }
  .container-games {
    width: 100% !important;
  }
}
