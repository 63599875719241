$opacity: 0.4;
$y: -100%;
$y2: 22%;
$color-primary: #0095fa;
.content-loader {
  transition: all ease-out 0.3s;
  position: fixed;
  width: 100vw;
  z-index: 10000000;
  align-items: center;
  background: radial-gradient(
    at 50% 100%,

    #272730 40%,
    #171720 80%,
    #070710 100%
  );
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding: 4rem 20px;
}
.loading {
  display: flex;
  justify-content: space-between;
  max-width: 72px;
  margin: 0 auto;
  width: 100%;
}

.dot {
  animation: loading 1s cubic-bezier(0.55, -0.77, 0.42, 1.79) 0s infinite normal
    both running;
  background: $color-primary;
  border-radius: 50%;
  display: block;
  height: 1rem;
  width: 1rem;

  $n: 3;
  @for $x from 2 through $n {
    &:nth-child(#{$x}) {
      animation-name: loading-#{$x};
    }
  }
}

@keyframes loading {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY($y2);
  }
  25% {
    transform: translateY($y);
  }
  50% {
    transform: translateY(0);
  }
}

@keyframes loading-2 {
  25% {
    transform: translateY(0);
  }
  29% {
    transform: translateY($y2);
  }
  50% {
    transform: translateY($y);
  }
  75% {
    transform: translateY(0);
  }
}

@keyframes loading-3 {
  50% {
    transform: translateY(0);
  }
  54% {
    transform: translateY($y2);
  }
  75% {
    transform: translateY($y);
  }
  100% {
    transform: translateY(0);
  }
}
